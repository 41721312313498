.shutterb-form {
  color: $color-secondary;
  .sb-form-success-msg {
    font-size: 18px;
    color: $color-text;
  }
  .shutterb-form-header {
    color: $color-secondary;
    font-weight: 500;
  }
  p {
    color: $color-text-paragraph;
    font-size: 14px;
    font-family: $sans-serif;
  }
  input[type="file"] {
    border: none;
  }
  .question-des {
    font-size: 14px;
  }
  .image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    width: 6.5rem;
    height: 6.5rem;
    .img-size {
      object-fit: cover;
      object-position: center;
    }
  }
  .sb-register-submit-btn {
    font-size: 16px !important;
    box-sizing: border-box;
    margin: auto;
    height: 40px;
  }
  // removes x icon on invalid submission, default styling of av
  .av-invalid {
    background-image: none;
  }
}

// for dropdown icon of  select and other common style in all form eventBooking form and shutterb form

.event-setup-form-box {
  color: $color-secondary;
  .icon-field-con {
    position: relative;

    .drop-arrow-con {
      width: fit-content;
      height: 32px;
      position: absolute;
      top: 40px;
      right: 30px;
    }
    .drop-location-con {
      width: fit-content;
      height: 16px;
      position: absolute;
      top: 0.25rem;
      right: 20px;
    }
    .invalid-feedback {
      padding-bottom: 10px;
    }
  }
  .icon-label-con {
    position: relative;

    .label-icon-con {
      width: fit-content;
      margin-left: 5px;
      height: 16px;
    }
  }
  p {
    color: $color-text-paragraph;
    font-size: 14px;
    font-family: $sans-serif;
  }
}
