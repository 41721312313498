.upload-gallery {
  font-family: $sans-serif;
  .cursor-pointer {
    cursor: pointer;
  }
  // maintain gallery controls sticky position and layer structure of images, controls and modal.
  .file-buttons {
    position: sticky;
    top: 0;
    z-index: 10;
    background-color: #f8f8f8;
  }
  .file-preview {
    z-index: 5;
  }
}
.ReactModal__Overlay {
  z-index: 999;
}
