section.home-banner.inner-banner {
    background: #424242;
    padding: 140px 0;
    @include breakpoint(desktop) {
       padding: 100px 0
         }
    .home-banner-content{
        position: relative;
        &::before{
            content: "";
            position: absolute;
            top: -100px;
            left: 100px;
            background-image: url(/images/hmm.png);
            background-repeat: no-repeat;
            background-size: contain;
            width: 230px;
            height: 230px;
            background-position: center;
        }
        h1{
            margin: auto;
            color: #fff;
        }
    }
}

.capturing-world{
    .heading{
        text-align: center;
        margin-bottom: 50px;
        color: #000;
        @include breakpoint(desktop) {
                  margin-bottom: 32px; 
               }
        h2{
            font-weight: 600;
        }
    }
    p{
        text-align: center;
        @include breakpoint(desktop) {
           font-size: 14px;
              }
    }
    figure{
        width: 100%;
        height: 557px;
        @include breakpoint(desktop) {
            height: 410px;
               }
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .row{
        margin-bottom: 20px;
    }
}
.how-we-work.core-values{
    position: relative;
    &::before{
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,0.6);
    }
    .heading{
        margin-bottom: 40px;
        position: relative;
        @include breakpoint(desktop) {
            margin-bottom: 30px; 
         }
    }
}
.our-let{
    padding-bottom: 0;
    p{
        text-align: center;
        margin-bottom: 50px !important;
        width: 100%;
        max-width: 1200px;
        margin: auto;
        @include breakpoint(desktop) {
            margin-bottom: 30px !important; 
         }
    }
    figure{
        width: 100%;
        height: 557px;
        @include breakpoint(desktop) {
            height: 370px;
         }
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}
.meet-shutterbz.our-shtrbz{
    .heading{
        margin-bottom: 35px;
    }
}