.shutterb-dashboard-con {
  color: white;
  // tab button  css
  button {
    font-weight: 500;
    font-size: 14px;
    color: $color-tabs-text;
  }

  // sb dashboard tabs btn style
  .MuiTabs-flexContainer {
    .sb-dashboard-tab-btn {
      min-width: 250px;
      clip-path: polygon(
        0 0,
        100% 0,
        100% 32.5%,
        97.5% 50%,
        100% 67.5%,
        100% 100%,
        0 100%
      );
    }
    .sb-dashboard-tab-btn[aria-selected="true"] {
      color: $color-white;
      background-color: $color-btn;
      border-right: unset;
      border: none;
    }
  }
  // make sidebar tabs btn right align
  .css-1h9z7r5-MuiButtonBase-root-MuiTab-root,
  .sb-dashboard-tab-btn {
    align-items: flex-end;
    font-size: 16px;
    font-family: $sans-serif;
  }
  // removes the tab indicator line from active tab
  .MuiTabs-indicator {
    display: none;
    // background-color: white;
    // width: 10px;
    // clip-path: polygon(0 50%, 100% 60%, 100% 40%);
  }
  // tabs content
  .tab-pannel {
    background-color: $color-text-paragraph-bg;
  }
  // removes extra padding of material ui
  .MuiBox-root {
    padding: 0;
  }
  // removes x icon on invalid submission on all forms in dashboard, default styling of av
  .av-invalid {
    background-image: none;
  }
  // Event Request Tab & My Events Tab--------------------------------
  .event-request-con {
    background-color: $color-white;
    margin-bottom: 16px;
    border-top: none;
    transition-duration: 0.3s;
    &:hover {
      -webkit-box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
        rgba(0, 0, 0, 0.3) 0px 1px 3px -1px,
        rgba(50, 50, 93, 0.25) 0px -2px 5px -1px,
        rgba(0, 0, 0, 0.3) 0px -1px 3px -1px;
      -moz-box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
        rgba(0, 0, 0, 0.3) 0px 1px 3px -1px,
        rgba(50, 50, 93, 0.25) 0px -2px 5px -1px,
        rgba(0, 0, 0, 0.3) 0px -1px 3px -1px;
      box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
        rgba(0, 0, 0, 0.3) 0px 1px 3px -1px,
        rgba(50, 50, 93, 0.25) 0px -2px 5px -1px,
        rgba(0, 0, 0, 0.3) 0px -1px 3px -1px;
    }
    h3 {
      font-size: 18px;
    }
    h5 {
      font-size: 14px;
    }
    button {
      padding: 7px 15px;
      background: #3e8993;
      border: 1px solid #3e8993;
      color: #fff;
      font-weight: 400;
      border-radius: 5px;
      font-size: 13px;
      margin-right: 5px;
      margin-top:2% !important;
      transition: all 0.3s ease-in-out 0s;
      &:hover {
        opacity: 0.8;
      }
    }
    .dec-button {
      background-color: #9c9c9c;
      border-color: #9c9c9c;
    }
    .event-request-header {
      cursor: pointer;
      background-color: $color-white;
      margin: 0;
      padding: 20px 10px;
      .event-header-text {
        color: $color-heading;
      }
      h3 {
        font-size: 16px;
      }
    }
    .event-request-body {
      padding-bottom: 20px;
      h5 {
        color: $color-event-body;
      }
    }
  }
  // reel events tab
  .reel-events-con {
    background-color: $color-white;
    margin-bottom: 16px;
    border-top: none;
    transition-duration: 0.3s;
    &:hover {
      -webkit-box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
        rgba(0, 0, 0, 0.3) 0px 1px 3px -1px,
        rgba(50, 50, 93, 0.25) 0px -2px 5px -1px,
        rgba(0, 0, 0, 0.3) 0px -1px 3px -1px;
      -moz-box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
        rgba(0, 0, 0, 0.3) 0px 1px 3px -1px,
        rgba(50, 50, 93, 0.25) 0px -2px 5px -1px,
        rgba(0, 0, 0, 0.3) 0px -1px 3px -1px;
      box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
        rgba(0, 0, 0, 0.3) 0px 1px 3px -1px,
        rgba(50, 50, 93, 0.25) 0px -2px 5px -1px,
        rgba(0, 0, 0, 0.3) 0px -1px 3px -1px;
    }
    h3 {
      font-size: 18px;
    }
    h5 {
      font-size: 14px;
    }
    .event-request-header {
      cursor: pointer;
      background-color: $color-white;
      margin: 0;
      padding: 20px 10px;
      .event-header-text {
        color: $color-heading;
      }
      h3 {
        font-size: 16px;
      }
    }
    .event-request-body {
      padding-bottom: 20px;
      h5 {
        color: $color-event-body;
      }
    }
  }
  // My Profile Tab-----------------------------------
  .sb-my-profil-con {
    // profile tabs btn styling
    .sb-my-profile-tabs {
      button[aria-selected="true"] {
        padding: 0px 25px;
        font-family: $sans-serif;
        color: black;
        font-weight: 600;
        background-color: white;
        border-top: 2px solid $color-btn;
      }
      button[aria-selected="false"] {
        padding: 0px 25px;
        font-family: $sans-serif;
        color: $color-tabs-disable;
        font-weight: 600;
        background-color: $color-bg-tabs-disable;
        // border-top: 1px solid $color-btn;
      }
    }
    .MuiBox-root {
      padding-top: 10px;
      padding-left: 5px;
      padding-right: 5px;
    }

    // my schedule tab styling
    .shutterb-dashboard-my-schedule {
      .check-table-cont {
        border: 0.1px solid #dadada;
        max-width: $my-sche-table-width;
        overflow-x: auto;
        table {
          text-indent: initial;
          border-collapse: separate;
          border-spacing: 0 10px;
          width: 100%;
          th {
            color: #000;
            padding: 8px 22.5px;
            text-align: inherit;
            display: table-cell;
            vertical-align: inherit;
            font-style: $sans-serif;
            &:first-child {
              width: 40%;
              white-space: nowrap;
              text-align: center;
            }
            &:nth-child(2) {
              width: 40%;
              white-space: nowrap;
              text-align: center;
            }
          }
          tr {
            display: table-row;
            vertical-align: middle;
            border-color: inherit;
            margin: 10px 15px;
            td {
              &:first-child {
                white-space: nowrap;
                text-align: start;
              }
              &:nth-child(2) {
                white-space: nowrap;
                text-align: start;
              }
              color: #000;
              padding: 0px 15px;
              font-size: 14px !important;
              font-weight: 600;
              font-family: $sans-serif;
              display: table-cell;
              vertical-align: middle !important;
              text-align: center;
              .form-group {
                margin: 0;
                padding: 0;
                .check_custom {
                  margin: 0;
                  padding: 0;
                  label {
                    margin: 0;
                    padding: 0;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: #5f5f5f;
                    cursor: default;
                    .check_box {
                      vertical-align: middle;
                      cursor: pointer;
                    }
                  }
                }
              }
            }
          }
          input[type="checkbox"] {
            display: none;
          }
          .check_box {
            height: 14px;
            width: 14px;
            background-color: $color-light-grey;
          }
          input:checked ~ .check_box {
            background-color: $color-btn;
          }
          li::before {
            content: "•";
            color: blue;
          }
        }
      }
      .table-btm-content {
        max-width: $my-sche-table-width;
        .avalaible-sign {
          width: 10px;
          height: 10px;
          background-color: $color-btn;
          margin-right: 10px;
        }
        .not-avalaible-sign {
          width: 10px;
          height: 10px;
          background-color: $color-light-grey;
          margin-right: 10px;
        }
        .select-deselect-all {
          margin-right: 10px;
          background-color: $color-light-grey;
        }
        label {
          font-size: 15px;
          font-weight: 500;
        }
      }
      .sch-sub-heading {
        color: $color-heading;
        font-weight: 500;
      }
      .blackout-days-con {
        .calendar-con {
          background-color: transparent;
          overflow: auto;
          .rmdp-day {
            width: 38px;
            height: 38px;
            :hover {
              background-color: transparent;
              color: inherit;
            }
          }
          .rmdp-selected {
            :hover {
              background-color: #0074d9;
              color: white;
            }
          }
        }
      }
      .set-save-btn {
        button {
          color: white;
        }
      }

      .tbl-holiday {
        overflow: auto !important;
        height: 287px;
        background-color: lightgray;
        z-index: 5;
        box-shadow: 0.5px 0.5px 4px 0.5px;
        border-radius: 5px;
      }

      .rdt_TableHead {
        position: sticky;
        top: 0;
        background-color: white;
        z-index: 999;
      }
    }

    // removes tab pannel x padding
    .MuiBox-root {
      padding-left: 0px;
      padding-right: 0px;
    }

    // my portfolio and my info tab styling
    .shutterb-dashboard-my-portfolio {
      color: $color-secondary;
      .shutterb-form-header {
        .shutterb-form-profile {
          margin: auto;
          border-radius: 50%;
          border: 1px solid black;
          width: fit-content;
          position: relative;
          .avatar {
            vertical-align: middle;
            width: 90px;
            height: 90px;
            border-radius: 50%;
          }
          .camera-icon-con {
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translate(-50%, -20%);
            transition-duration: 0.3s;
            &:hover {
              color: $color-white;
              cursor: pointer;
            }
          }
        }
      }

      .icon-field-con {
        position: relative;

        .drop-arrow-con {
          width: fit-content;
          height: 20px;
          position: absolute;
          top: 45%;
          right: 20px;
        }
        .drop-location-con {
          width: fit-content;
          height: 16px;
          position: absolute;
          top: 10%;
          right: 20px;
        }
      }
      .icon-label-con {
        position: relative;

        .label-icon-con {
          width: fit-content;
          height: 16px;
          position: absolute;
          top: 0;
          right: 30px;
        }
      }
      p {
        color: $color-text-paragraph;
        font-size: 14px;
        font-family: $sans-serif;
      }
      input[type="file"] {
        border: none;
      }
      .shutterb-portfolio-btn {
        button {
          height: 50px;
          font-size: 20px;
          padding: 0 20px;
          line-height: 2.5;
          border: none;
          text-transform: capitalize;
          &:hover {
            box-shadow: none;
            opacity: 0.7;
            background-color: $color-btn;
            color: $color-white;
          }
        }
        .btn-not-allowed {
          cursor: not-allowed;
        }
      }
      .my-port-gallary-con {
        // overflow: "auto";
        .form-group {
          margin-bottom: 0px;
        }
        .my-portfolio-gallery-App {
          background-color: $color-white;
          .image-item {
            display: flex;
            margin: 10px 0;
          }
          .image-item__btn-wrapper {
            display: flex;
            flex-direction: column;
            margin-left: 10px;
          }
          .gallery-wrapper {
            // uncoment to make the gallery overfloaable
            // max-height: 150px;
            // overflow: auto;
            display: grid;
            grid-template-columns: repeat(auto-fill, 100px);
            justify-content: space-between;
            grid-gap: 20px;
            .image-item__btn-wrapper {
              position: relative;
              a {
                background: #656565;
                border-radius: 100px;
                width: 17px;
                height: 17px;
                opacity: 1;
                color: $color-white;
                display: flex;
                align-items: center;
                justify-content: center;
                position: absolute;
                padding: 5px;
                top: -5px;
                right: 5px;
                &:hover {
                  opacity: 0.8;
                  cursor: pointer;
                }
              }
            }
            .image-item {
              width: 100px;
              height: 100px;
              img {
                width: 100%;
                height: 100%;
                padding: 0;
                margin: 0;
                object-fit: cover;
                object-position: center;
              }
            }
          }
          .upload__image-wrapper {
            .img-upload-btn {
              cursor: pointer;
              transition-duration: 0.3s;
              box-sizing: border-box;
              display: flex;
              justify-content: center;
              align-items: center;
              &:hover {
                opacity: 0.8;
              }
            }
          }
        }
      }
    }
  }
}
// hides disable holiday time picker
.react-datepicker__time-list-item--disabled {
  display: none;
}

// reel detail modal
.reel-detail-con {
  border: 1px solid $color-btn;
  border-radius: 5px;
  margin: auto;
  padding-top: 20px;
  padding-bottom: 10px;
  overflow: auto;
  background-color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 75vw;
  max-width: 90vw;
  min-height: 50vh;
  max-height: 90vh;
  overflow: visible;
  h5 {
    text-align: center;
  }
  .main-con {
    min-width: 75vw;
    min-height: 50vh;
    max-height: 80vh;
    overflow: auto;

    table {
      width: 100%;
      margin: 4px;
      thead {
        td {
          text-align: center;
          color: black;
          font-size: 20px;
          font-weight: 500;
        }
      }
      tbody {
        display: table;
        margin-top: 14px;
        th {
          font-size: 12px;
          vertical-align: top;
          width: 20%;
          padding: 10px;
        }
        td {
          font-size: 12px;
          vertical-align: top;
          padding: 10px;
          word-break: break-all;
        }
      }
    }
  }
  .close {
    color: black;
    opacity: 1;
    background-color: white;
    border: 1px solid $color-btn;
    padding: 6px 10px;
    border-radius: 50%;
    font-size: 16px;
    position: absolute;
    right: -10px;
    top: -10px;
    cursor: pointer;
  }
}

.message-board-modal {
   width: 80%; /* Adjust the width as per your preference */
  height: 80%; /* Adjust the height as per your preference */
  max-width: 400px; /* Maximum width of the modal */
  max-height: 400px; /* Maximum height of the modal */
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  // position: fixed;
  // top: 50%;
  // left: 50%;
  // transform: translate(-50%, -50%);
  // min-width: 80vw;
  // max-width: 80vw;
  // min-height: 50vh;
  // max-height: 95vh;
  // overflow: visible;
  h5 {
    text-align: center;
  }
  .main-con {
    min-width: 30vw;
    min-height: 50vh;
    max-height: 80vh;
    // overflow: auto;
  }
  .close {
    color: black;
    opacity: 1;
    background-color: white;
    border: 1px solid $color-btn;
    padding: 6px 10px;
    border-radius: 50%;
    font-size: 16px;
    position: absolute;
    right: -1%;
    top: -10px;
    z-index: 100;
    cursor: pointer;
  }
}
.modal-header {
  height: 9%;
  background-color: #3e8993;
  padding: 14px;
  h6 {
    color: #fff;
    font-size: 14px;
    padding: 0;
    margin: 0;
  }
  span {
    color: #fff;
    font-size: 14px;
    padding: 0;
    cursor: pointer;
  }
}
.theme-border-top {
  border-top: #3e8993 2px solid;
}
.holiday-profile-modal {
  width: 80%; /* Adjust the width as per your preference */
  height: 80%; /* Adjust the height as per your preference */
  max-width: 400px; /* Maximum width of the modal */
  max-height: 320px; /* Maximum height of the modal */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.event-detail-modal {
  width: 100%; /* Adjust the width as per your preference */
  // height: 100%; /* Adjust the height as per your preference */
  max-width: 1000px; /* Maximum width of the modal */
  max-height: 500px; /* Maximum height of the modal */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.notification-zindex{
  z-index: 1000 !important;
}

/* The sidebar menu */
.notification-sidebar {
  height: 100%; /* 100% Full-height */
  width: 0; /* 0 width - change this with JavaScript */
  position: fixed; /* Stay in place */
  z-index: 1; /* Stay on top */
  top: 0;
  right: 0;
  background-color: #fff; /* Black*/
  overflow-x: hidden; /* Disable horizontal scroll */
  padding-top: 60px; /* Place content 60px from the top */
  transition: 0.5s; /* 0.5 second transition effect to slide in the sidebar */
  // border-left: groove #3e8993 2px;
  border-left: groove lightgrey 1px;
  box-shadow: 0px 1px 1px 1px lightgrey;
}

/* The sidebar links */
.notification-sidebar a {
  padding: 10px 10px 10px 10px;
  text-decoration: none;
  font-size: 14px;
  background-color: #f0eeee;
  display: block;
  transition: 0.3s;
  cursor: pointer;
}

/* When you mouse over the navigation links, change their color */
.notification-sidebar a:hover {
  background-color: rgb(202, 202, 202);
}

/* Position and style the close button (top right corner) */
.notification-sidebar .closebtn {
  position: absolute;
  top: 0;
  // padding-right: 25px;
  font-size: 36px;
  // margin-left: 50px;
  // text-align: right;
  background-color: #3e8993;
  color: #fff;
  // cursor: pointer;
  width: 100%;
  display: flex;
  flex-wrap: none;
  align-items: center;
}
.sidebar-notifications {
  text-align: left;
  font-size: 14px;
}
#notification-main {
  transition: margin-rigth 0.5s; /* If you want a transition effect */
  padding: 20px;
  visibility: hidden;
}

.notification-heading {
  text-align: left;
  display: flex;
  color: #fff;
  font-size: 14px;
  margin-bottom: 0;
  padding-left: 20px;
  align-items: center;
  width: 85%;
}
.empty-notification-panel {
  top: 20%;
  position: relative;
  text-align: center;
}
.notification-img{
  width: 30px;
  height: 30px;
  margin-right: 10px;
  margin-left: 10px;
}
.chat {
  margin-top: auto;
  margin-bottom: auto;
}
.msg-card {
  height: 500px;
  border-radius: 15px !important;
  background-color: #fff !important;
}
.contacts_body {
  padding: 0.75rem 0 !important;
  overflow-y: auto;
  white-space: nowrap;
}
.msg_card_body {
  overflow-y: auto;
}
.msg-card-header {
  border-radius: 15px 15px 0 0 !important;
  border-bottom: 0 !important;
  background-color: #3e8993 !important;
}
.msg-card-footer {
  border-radius: 0 0 15px 15px !important;
  border-top: 0 !important;
  background-color: #3e8993 !important;
}
.search {
  border-radius: 15px 0 0 15px !important;
  background-color: rgba(0, 0, 0, 0.3) !important;
  border: 0 !important;
  color: white !important;
}
.search:focus {
  box-shadow: none !important;
  outline: 0px !important;
}
.type_msg {
  background-color: #fff !important;
  border: 0 !important;
  color: black !important;
  height: 42px !important;
  overflow-y: auto;
}
.type_msg:focus {
  box-shadow: none !important;
  outline: 0px !important;
}
.attach_btn {
  border-radius: 15px 0 0 15px !important;
  background-color: rgba(0, 0, 0, 0.3) !important;
  border: 0 !important;
  color: white !important;
  cursor: pointer;
}
.send_btn {
  border-radius: 0 15px 15px 0 !important;
  background-color: rgba(0, 0, 0, 0.3) !important;
  border: 0 !important;
  color: white !important;
  cursor: pointer;
}
.search_btn {
  border-radius: 0 15px 15px 0 !important;
  background-color: rgba(0, 0, 0, 0.3) !important;
  border: 0 !important;
  color: white !important;
  cursor: pointer;
}
.contacts {
  list-style: none;
  padding: 0;
}
.contacts li {
  width: 100% !important;
  padding: 0px 8px;
  margin-bottom: 15px !important;
  cursor: pointer;
}
.active {
  background-color: rgba(0, 0, 0, 0.3);
}
.user_img {
  height: 50px;
  width: 50px;
  border: 1.5px solid #f5f6fa;
  border-radius: 50%;
}
.user_img_msg {
// height: 50px;
// width: 40px;
  border: 1.5px solid #f5f6fa;
}
.img_cont {
  position: relative;
  // height: 70px;
  width: 70px;
  display: flex;
  align-items: center;
}
.img_cont_msg {
    position: relative;
    height: 58px;
    width: 59px;
    display: flex;
    align-items: center;
}
.online_icon {
  position: absolute;
  height: 15px;
  width: 15px;
  background-color: #4cd137;
  border-radius: 50%;
  bottom: 0.2em;
  right: 0.4em;
  border: 1.5px solid white;
}
.offline {
  background-color: #c23616 !important;
}
.user_info {
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 15px;
}
.user-info-text{
  align-items: center;
  display: flex;
  overflow-x: hidden;
  width: fit-content;
  position: relative;
  max-width: 100%;
  color: #000;
  word-wrap: break-word;
}
.user_info span {
  font-size: 18px;
  color: white;
  font-weight: 600;
}
.user_info p {
  font-size: 10px;
  color: rgba(255, 255, 255, 0.6);
}
.video_cam {
  margin-left: 50px;
  margin-top: 5px;
}
.video_cam span {
  color: white;
  font-size: 20px;
  cursor: pointer;
  margin-right: 20px;
}
.msg_cotainer {
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 10px;
  border-radius: 10px;
  padding: 10px;
  position: relative;
  width: fit-content;
  max-width: 100%;
  background-color: lightgrey;
  color: #000;
  word-wrap: break-word;
  padding-left: 25px;
  padding-right: 25px;
  padding-bottom: 20px;
    font-size: 13px;

}
.msg_cotainer_send {
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 10px;
  border-radius: 10px;
  background-color: #3e8993;
  padding: 10px;
  position: relative;
  width: fit-content;
  max-width: 100%;
  color: #fff;
  word-wrap: break-word;
  padding-left: 25px;
  padding-right: 25px;
  padding-bottom: 20px;
  font-size: 13px;
}
.msg_time {
  position: absolute;
  right: 0;
  bottom: 0px;
  font-weight: 600;
  color: #919090;
  font-size: 9px;
  padding-top: 2px;
  padding-bottom: 2px;
  padding-right: 5px;
}
.msg_time_send {
  position: absolute;
  right: 0;
  bottom: 0px;
  color: #d8d6d6;
  font-weight: 600;
  font-size: 9px;
padding-top: 2px;
  padding-bottom: 2px;
  padding-right: 5px;
}
.msg_head {
  position: relative;
}
#action_menu_btn {
  position: absolute;
  right: 10px;
  top: 10px;
  color: white;
  cursor: pointer;
  font-size: 20px;
}
.action_menu {
  z-index: 1;
  position: absolute;
  padding: 15px 0;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border-radius: 15px;
  top: 30px;
  right: 15px;
  display: none;
}
.action_menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.action_menu ul li {
  width: 100%;
  padding: 10px 15px;
  margin-bottom: 5px;
}
.action_menu ul li i {
  padding-right: 10px;
}
.action_menu ul li:hover {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.2);
}
@media (max-width: 576px) {
  .contacts_card {
    margin-bottom: 15px !important;
  }
}
