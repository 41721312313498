/*For Developer Use*/

.modal-body.image-body {
    text-align: center;
}

a.btn-disable {
    pointer-events: none;
    background: #9c9c9c !important;
    border: 1px solid #9c9c9c !important;
}

a.disable-extend {
    pointer-events: none;
    color: #a3a3a3 !important;
}

.my-event-detail .disable-extend {
    pointer-events: none;
    filter: grayscale(1);
}

a.disabled-cls {
    pointer-events: none;
}

.bg-pt-events .access-images-album {
    padding: 20px 0px 20px 0;
}

.search_list li a {
    color: #3e8993;
    font-size: 16px;
}

.recent_search h5 {
    margin: 15px 0 15px;
    font-size: 18px;
}

footer .footer-social-links ul li a svg {
    width: 16px;
    height: 16px;
    fill: #fff;
    position: relative;
    top: -2px;
}

footer .footer-social-links ul li a:hover svg {
    fill: #405DE6;
}

.overflow-h {
    overflow: hidden !important;
}

.sign-check-row {
    display: flex;
}

.sign-check-row .form-control {
    width: 25px;
    padding-left: 10px;
}

.form-group.f-sign-check-row label span {
    color: #fff;
}

.sign-check-row label {
    width: calc(100% - 25px);
    padding-left: 10px;
}

footer .footer-newsletter form {
    flex-wrap: wrap;
}

.form-group.f-sign-check-row {
    width: 100% !important;
    margin: 0 12px;
}

.form-group.f-sign-check-row .form-control {
    background: transparent;
    border: none;
    padding: 0;
    text-align: center;
}

.form-group.f-sign-check-row label input {
    width: 25px;
    height: 25px;
}

.form-group.checkbox-vih {
    display: flex;
}

.form-group.checkbox-vih input#vehicle1 {
    width: 24px;
    margin-right: 8px;
    height: 24px;
    margin-top: 6px;
}

.form-group.checkbox-vih label {
    width: calc(100% - 28px);
}

.account-info-tab.remove-border .personal-info:after {
    display: none;
}

.account-info-tab.remove-border .personal-info {
    width: 100%;
    margin-right: 0;
}

.upload-btn-wrapper form {
    position: relative;
}

button.btn.checkBtn.grey-out {
    background: #979797;
}

button.btn.checkBtn.grey-out:hover {
    background: #979797 !important;
}

.dash-rqst.upcming .rqst-accept-decline-btn a.green-btn {
    background: #3e8993 !important;
}

a.view-btn {
    padding: 2px 60px;
    background: #3e8993;
    color: white;
    margin-left: 13px;
    border-radius: 5px;
}

.view-gallery video {
    width: 100% !important;
    height: 100% !important;
    object-fit: cover;
    object-position: center;
}

input.shot_name {
    border: none;
    background: transparent;
}

.lity.lity-opened {
    z-index: 999999;
}

.view-gallery .form-group {
    width: auto;
    height: 450px;
}

.view-gallery .form-group a img {
    width: 100%;
    object-fit: cover;
    height: 100%;
}

.eventt-booking-profiles .both-sliders .single-slider button.slick-next.slick-arrow:before,
.eventt-booking-profiles .both-sliders .single-slider button.slick-prev.slick-arrow:before {
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    padding: 0;
    box-shadow: 0 0 10px #cbcbcb;
    opacity: 1;
}

footer .footer-newsletter form span.wpcf7-spinner {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.progress_overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 9999;
}

.progress_overlay:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(2, 2, 2, 0.88);
    z-index: 999;
    width: 100%;
    height: 100vh;
}

.progress_overlay .progress {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    width: 80%;
    margin: 0 auto;
    z-index: 999999;
    transform: translateY(-50%);
}

.progress_overlay .bar {
    background: #3e8993;
}

.progress_overlay .percent {
    font-size: 12px;
    font-weight: 600;
    line-height: 15px;
    text-align: center;
    width: 100%;
    position: absolute;
    color: #fff;
    height: 100%;
}

.page-id-173 .woocommerce-info {
    text-align: left;
}

.page-id-173 p {
    text-align: center;
}

.page-id-173 a.button.wc-backward {
    padding: 7px 15px;
    background: #3e8993;
    border: 1px solid #3e8993;
    color: #fff;
    font-weight: 500;
    border-radius: 5px;
    font-size: 16px;
}

.upload-btn-wrapper form {
    display: flex;
    align-items: center;
}

.upload-preloader {
    margin-left: 10px;
}

.upload-preloader img {
    width: 35px;
}

.access-imagess {
    margin-bottom: 15px;
}

.access-imagess figure {
    width: 245px;
    height: 277px;
    margin-bottom: 0;
}

.access-imagess figure img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.access-imagess table {
    width: 100%;
}

.access-imagess table th {
    vertical-align: baseline;
    padding-bottom: 10px;
    font-weight: 600;
    color: #5f5f64;
}

.access-imagess table td {
    padding-bottom: 10px;
    color: #000;
    font-weight: 500;
}

.access-imagess table td ul li figure {
    width: 105px;
    height: 110px;
}

.access-imagess table td ul li:nth-last-of-type(2) {
    font-size: 14px;
    color: #000;
    font-weight: 700;
}

.access-imagess table td ul li:last-child {
    font-size: 17px;
    font-weight: 500;
    color: #5f5f64;
}

.access-images span {
    display: block;
    text-align: right;
    color: #000;
    font-weight: bold;
    font-size: 20px;
}

.access-images .row {
    margin-bottom: 30px;
}

.past-events-two .short-list-btn {
    margin-bottom: 35px !important;
}

.upload-btn-wrapper form {
    display: flex;
    align-items: center;
    margin: 0 12px;
}

.past-events-two .upload-btn-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}

section.thank-you.book-your-event.event-book-flow.event-product-page.event-booking-form.bee-yt {
    padding-top: 20px !important;
}

dt.variation- {
    display: none !important;
}

.modal-open .moda1451l {
    z-index: 9999;
}

form#shutterbee-profile-form .avatar-upload .avatar-preview div#imagePreview {
    width: 100px;
    height: 100px;
    border-radius: 100%;
}

/*
.coupon {
    display: none;
}
*/

/*

section.promocode-bnr.animate__animated.animate__bounce.head-checkout {

    padding: 90px 0 10px 0;

    margin-bottom: 2px;

}

section.promocode-bnr.animate__animated.animate__bounce.alert.alert-success{

    margin: 2px;

}*/

.extend-your-shutter-bee .custom-radio-wrap form {
    display: flex;
}

.page-id-554 .access-images-album .access-images-album-check-list .form-group {
    height: 204px;
}

.page-id-360 .bg-pt-events {
    background-color: #f8f8f8;
    padding: 20px 25px 20px 25px;
}

.page-id-360 .access-images-album .access-images-album-check-list .form-group {
    height: 204px;
}

.access-images-album .access-images-album-check-list .form-group img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

input#myInput {
    border: none;
    padding: 10px 20px;
    width: 100%;
}

.uploaded-video {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

li.cart-dot::before {
    content: "";
    position: absolute;
    left: calc(50% - 6px);
    top: -24px;
    width: 12px;
    height: 12px;
    border-radius: 100%;
    background: #3e8993 !important;
    z-index: 9;
}

.event-booking-form-box.become-bee-form form .upload-btn-wrapper {
    position: inherit;
    overflow: inherit;
}

.upload-btn {
    position: relative;
}

.upload-btn label.btn-green {
    display: inline-block;
    background-color: transparent;
    padding: 5px 12px;
    border-radius: 2px;
    cursor: pointer;
    border: 1px solid #3e8993;
    margin: 0;
}

.block-lavel {
    display: block;
}

output#result {
    width: 100%;
    display: flex;
    align-items: center;
}

.col-lg-4.abc {
    margin-bottom: 40px !important;
}

div#stripe-card-element {
    width: 200px;
}

div#stripe-exp-element {
    width: 200px;
}

div#stripe-cvc-element {
    width: 200px;
}

table.shop_table.woocommerce-checkout-review-order-table {
    display: none;
}

h3#order_review_heading {
    display: none;
}

.modal {
    z-index: 9999;
}

header .navbar-nav li a {
    color: #000 !important;
    text-transform: uppercase;
    font-size: 14px !important;
    font-weight: 600 !important;
}

header .navbar-nav {
    align-items: center;
}

.access-images-album cite {
    background: transparent;
}

.access-images-album cite button.btn {
    left: 20px;
}

.home-functions figure {
    max-height: 363px;
}

.profile-slider-content .left-right-fig .right-content {
    width: calc(100% - 80px);
}

.profile-slider-content .left-right-fig .right-content input[type="checkbox"] {
    float: left;
    margin-right: 5px;
    margin-top: 4px;
}

body .gj-modal {
    z-index: 9999;
}

.event-booking-form-box .gj-timepicker.gj-timepicker-md.gj-unselectable {
    background: #efefef;
}

.eventt-booking-profiles .both-sliders .single-slider .profile-slider.slick-initialized.slick-slider {
    max-height: 338px;
    margin: 0;
}

.eventt-booking-profiles .both-sliders .single-slider .profile-slider.slick-initialized.slick-slider .profile-slider-box figure {
    margin-bottom: 0;
}

.eventt-booking-profiles .both-sliders .single-slider .profile-slider.slick-initialized.slick-slider .profile-slider-box figure img {
    width: 100%;
}

.woocommerce .cart-collaterals .cart_totals,
.woocommerce-page .cart-collaterals .cart_totals {
    margin-top: 40px;
    display: flex;
    align-items: center;
    width: 100%;
    max-width: inherit;
    flex-direction: column;
}

.woocommerce .cart-collaterals .cart_totals,
.woocommerce-page .cart-collaterals .cart_totals .wc-proceed-to-checkout {
    display: flex;
    align-items: center;
    width: 100% !important;
    justify-content: flex-end;
}

.cart_totals h2 {
    text-align: center;
}

a.btn.checkout-button.button.alt.wc-forward {
    background: #3e8993;
    font-size: 20px;
    height: 54px;
    line-height: 0.8;
    margin: 0 0px 0 35px;
    font-weight: 500;
}

.thank-you.book-your-event.event-book-flow.event-product-page .product-page-cart-table table tr td {
    padding: 20px 40px 20px 40px;
}

a.btn.checkout-button.button.alt.wc-forward:first-child {
    background: transparent;
    color: #646464;
    border: 2px solid #747474;
    line-height: 0.6;
}

input#coupon_code {
    width: 150px;
    font-size: 15px;
}

.thank-you.book-your-event.event-book-flow.event-product-page .product-page-cart-table table tr.subtotal td {
    text-align: right;
}

.cart_totals .table.shop_table.shop_table_responsive th {
    border: 1px solid #dcdcdc;
}

.woocommerce .cart-collaterals,
.woocommerce-page .cart-collaterals {
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
}

.thank-you.book-your-event.event-book-flow.event-product-page .product-page-cart-table table tr.subtotal td {
    text-align: right;
    padding: 0;
}

.event-booking-profile2 .event-book-profile-gallery figure img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    background: transparent;
}

.thank-you.book-your-event.event-book-flow.event-product-page .product-page-cart-table table tr th {
    text-align: left;
    background: transparent;
}

.thank-you.book-your-event.event-book-flow.event-product-page .product-page-cart-table table tr.subtotal td {
    text-align: right;
    padding: 14px 20px;
}

.quantity input {
    border: 1px solid #dcdcdc;
}

/* .thank-you.book-your-event.event-book-flow.event-product-page .product-page-cart-table table tr td.product-remove {
    padding-left: 10px;
} */

.woocommerce a.remove {
    color: #3e8993 !important;
    text-indent: -9999px;
    position: relative;
   /* background: url("../images/trash.png");*/
    background-repeat: no-repeat;
    background-position: center;
    background-size: 14px;
}

.woocommerce a.remove:hover {
   /* background: #3e8993 url("../images/trash-white.png") !important;*/
    background-repeat: no-repeat !important;
    background-position: center !important;
    background-size: 14px !important;
}

/* 14-12-2021 changes */

.profile-slider-content .profile-slider-btns {
    position: absolute;
    bottom: 10px;
    left: 0;
    right: 0;
}

.gj-timepicker.gj-timepicker-md.gj-unselectable {
    background-color: #fff !important;
}

div#ajaxData {
    padding: 0px !important;
}

.profile-slider-content .lower-content p {
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    -webkit-hyphens: manual;
    -ms-hyphens: manual;
    hyphens: manual;
}

.profile-slider-content .left-right-fig .right-content h6 {
    font-size: 18px;
}

.reserve-britney .left-right .right .reserve-britney-btn a.btn {
    color: #fff;
}

.reserve-britney .left-right .left p i.fa.fa-star {
    color: #3e8993;
}

.tab-content.dash-settin .avatar-upload {
    max-width: inherit !important;
}

.tab-content.dash-settin .avatar-upload .avatar-preview {
    margin-right: 20px;
    margin-bottom: 30px;
}

.tab-content.dash-settin .dash-setting-content .avatar-upload.avtar-bottom {
    display: flex;
    width: 100%;
    align-items: flex-start;
}

.tab-content form .form-group input.form-control {
    border-radius: 0;
    color: #000;
    font-weight: 500;
    background: #fff;
}

.tab-content.dash-settin .avatar-upload .avatar-edit {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: flex-start;
    margin-right: 20px;
    position: relative;
    left: 0;
    transform: none;
    bottom: 0;
    flex-wrap: wrap;
    /*max-width: 125px;
    min-height: 125px;*/
}

.avatar-upload.avtar-bottom .portfolio_images {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
}

#toast-container .toast-success {
    background-color: #3e8993 !important;
}

.profile-slider-content {
    position: relative;
}

span.remove {
    font-size: 14px;
}

span.imageName {
    font-size: 13px;
    white-space: nowrap;
    overflow: hidden;
    max-width: 125px;
    display: inline-block;
    text-overflow: ellipsis;
}

.upload-btn-wrapper .upload-btn img {
    object-fit: cover;
}

.modal-content .event-booking-form-box .gj-timepicker.gj-timepicker-md.gj-unselectable {
    background: #efefef !important;
}

.modal-content div#ajaxData {
    border: 1px solid #dcdcdc;
}

.modal-content form .form-group select {
    border-radius: 0px;
}

.modal-content form .form-group textarea {
    border-radius: 0px;
}

.modal-content form .form-group .gj-timepicker.gj-timepicker-md.gj-unselectable input#input {
    height: 56px;
}

.modal-content .gj-timepicker.gj-timepicker-md.gj-unselectable {
    height: 56px;
}

.modal-content form .form-group .gj-timepicker.gj-timepicker-md.gj-unselectable input#input2 {
    height: 56px;
}

.home-login-modal .modal-content {
    padding: 0 25px;
}

.home-login-modal .modal-content .event-booking-form-btn button.btn {
    height: 50px;
    font-size: 20px;
    width: 200px;
    display: flex;
    justify-content: center;
    line-height: 2.7;
}

.home-login-modal .modal-content .event-booking-form-btn {
    margin: auto;
}

.entry-content td.product-name.details dt {
    min-width: 100px;
}

.thank-you .common_bee_name {
    display: flex;
    margin-bottom: 3px;
}

.thank-you .bee_name {
    width: 100px;
    font-weight: 700;
}

.thank-you .cart-name {
    font-weight: 500;
    width: calc(100% - 100px);
}

.entry-content td.product-name.details dd p {
    text-align: left;
}

.thank-you .event-booking-payment figure {
    width: 120px;
    height: 120px;
    max-width: 120px;
    margin: auto;
}

.thank-you .event-booking-payment figure img {
    width: 100% !important;
    height: 100% !important;
    max-width: 100% !important;
    object-fit: cover;
}

.event-booking-payment ul {
    margin-bottom: 30px;
}

.thank-you select {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

/* 14-12-2021 changes end */

/********* First Range slider *****/

.wrapper {
    width: 400px;
    height: 50px;
    position: absolute;
    bottom: 0;
    top: 0;
    right: 0;
    left: 0;
    margin: auto;
    display: block;
}

.wrapper h1 {
    color: #fff;
    text-align: center;
    margin-bottom: 25px;
}

input[type="range"] {
    -webkit-appearance: none;
    outline: 0;
}

.rang {
    z-index: 1;
}

input[type="range"]::-webkit-slider-runnable-track {
    width: 100%;
    height: 3px;
    z-index: 1;
    background-color: rgba(12, 12, 12, 0.3);
}

.value_ident {
    width: 0px;
    height: 3px;
    background: #fff;
    position: absolute;
    display: block;
    z-index: 2;
}

input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 25px;
    width: 25px;
    border-radius: 100%;
    background: #4099ff;
    cursor: pointer;
    border: 3px solid #fff;
    margin-top: -10px;
    z-index: 99;
    transition: transform 300ms cubic-bezier(0.68, -0.55, 0.265, 1.55), opacity 300ms linear;
}

input[type="range"]::-webkit-slider-thumb:hover {
    opacity: 0.8;
}

input[type="range"]::-webkit-slider-thumb:active {
    transform: scale(1.5);
    background: #fff;
    opacity: 1;
}

#percent {
    width: 60px;
    position: relative;
    left: -14px;
    top: 20px;
    opacity: 0;
    background: #fff;
    margin-bottom: 20px;
    color: #4099ff;
    font-size: 16px;
    padding: 12px;
    font-weight: bold;
    text-align: center;
    transition: opacity 350ms cubic-bezier(0.425, -0.485, 0.515, 1.315), top 600ms cubic-bezier(0.425, -0.485, 0.515, 1.315);
}

#percent.active {
    top: -10px;
    opacity: 1;
}

.dash-sett .lbl {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    top: -18px;
}

/********** Range slider ********/

/* Building parts */

.bldg {
    margin: 0 auto 3em auto;
    overflow: hidden;
    position: relative;
    width: 12em;
    height: 12em;
}

.bldg__roof,
.bldg__main,
.bldg__main:before {
    position: absolute;
}

.bldg__roof,
.bldg__main {
    left: 50%;
    transform: translateX(-50%) scale(calc(1 / var(--floors)));
    transition: transform 0.3s cubic-bezier(0, 0, 0.25, 1.25);
}

.bldg__roof,
.bldg__main:before {
    width: 11em;
}

.bldg__roof {
    background: currentColor;
    border-radius: 0.5em;
    top: 0;
    transform-origin: 50% 0;
    height: 1.5em;
    z-index: 2;
}

.bldg__main,
.bldg__main:before {
    bottom: 0;
}

.bldg__main {
    background: linear-gradient(0deg, var(--fg) 0.5em, var(--fgT) 0.5em) 0 0 / 100% 100%, linear-gradient(90deg, var(--fg) 5%, var(--fgT) 5% 95%, var(--fg) 95%) 0 0 / 100% 100%, linear-gradient(var(--bg) 25%, var(--bgT) 25% 75%, var(--bg) 75%) 0 100% / 4em 12em, linear-gradient(90deg, var(--bg) 50%, var(--fg) 50%) 0 100% / 4em 12em;
    border-radius: 0 0 1em 1em;
    transform-origin: 50% 100%;
    width: 10em;
    height: calc(12em * var(--maxFloors));
}

.bldg__main:before {
    background: radial-gradient(3em 3em at 1.5em 50%, var(--fg) 48%, var(--fgT) 50%), radial-gradient(2em 2em at 3em 2em, var(--fg) 48%, var(--fgT) 50%), radial-gradient(1em 1em at 4em 2.5em, var(--fg) 48%, var(--fgT) 50%), radial-gradient(1em 1em at 7em 2.5em, var(--fg) 48%, var(--fgT) 50%), radial-gradient(2em 2em at 8em 2em, var(--fg) 48%, var(--fgT) 50%), radial-gradient(3em 3em at 9.5em 50%, var(--fg) 48%, var(--fgT) 50%);
    content: "";
    display: block;
    left: -0.5em;
    height: 3em;
    z-index: 1;
}

/* Range slider */

.range:not(:last-child) {
    margin-bottom: 1.5em;
}

.range input[type="range"],
.range input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
}

.range input[type="range"],
.range__input-fill {
    border-radius: 0.25em;
    height: 0.5em;
}

.range input[type="range"] {
    /*   background-color: var(--inputBg);*/
    display: block;
    margin: 0.5em 0;
    padding: 0;
}

.range input[type="range"]:focus {
    outline: transparent;
}

.range input[type="range"]::-webkit-slider-thumb {
    background-color: var(--handleBg);
    border: 0;
    border-radius: 50%;
    cursor: pointer;
    position: relative;
    transition: background 0.1s linear;
    width: 1.5em;
    height: 1.5em;
    z-index: 1;
}

.range input[type="range"]::-moz-range-thumb {
    background-color: var(--handleBg);
    border: 0;
    border-radius: 50%;
    cursor: pointer;
    position: relative;
    transform: translateZ(1px);
    transition: background-color 0.1s linear;
    width: 1.5em;
    height: 1.5em;
    z-index: 1;
}

.range input[type="range"]::-moz-focus-outer {
    border: 0;
}

.range__input,
.range__input-fill {
    display: block;
}

.range__input {
    margin-right: 0.375em;
    position: relative;
}

.range__input:active input[type="range"]::-webkit-slider-thumb,
.range input[type="range"]:focus::-webkit-slider-thumb,
.range input[type="range"]::-webkit-slider-thumb:hover {
    background-color: var(--handleDownBg);
}

.range__input:active input[type="range"]::-moz-range-thumb,
.range input[type="range"]:focus::-moz-range-thumb,
.range input[type="range"]::-moz-range-thumb:hover {
    background-color: var(--handleDownBg);
}

.range__input-fill,
.range__counter {
    left: 0;
    pointer-events: none;
    position: absolute;
}

.range__input-fill {
    background-color: var(--handleTrackBg);
    top: calc(50% - 0.25em);
}

.range__counter {
    color: #fff;
    display: block;
    font-size: 12px;
    top: -10px;
    text-align: center;
    width: 1.5rem;
    height: 1.5rem;
    z-index: 2;
}

/* Dark mode */

@media (prefers-color-scheme: dark) {
    :root {
        --bg: #2e3138;
        --bgT: #2e313800;
        --fg: #e3e4e8;
        --fgT: #e3e4e800;
        --inputBg: #17181c;
    }
}

/******** End of range slider *******/

.avatar-preview {
    position: relative;
    display: inline-block;
}

.close {
    position: absolute;
    top: 0;
    right: 0;
}

.main.calender {
    width: 50%;
}

.main.calender .tavo-calendar__header {
    padding: 16px 10px;
    background-color: #b6bdc4;
}

.main.calender span.tavo-calendar__month-label {
    font-size: 18px;
    font-weight: 600;
    color: #5f5f5f;
}

.main.calender .tavo-calendar__nav {
    fill: #7f8a98;
}

.main.calender .tavo-calendar__week-names {
    font-size: 14px;
    padding: 14px 0;
}

.main.calender .tavo-calendar__day_off,
.tavo-calendar__day_exp,
.tavo-calendar__day_different-month,
.tavo-calendar__day_lock {
    padding: 12px 0;
}

.tavo-calendar__day {
    padding: 12px 0;
}

.main.calender .tavo-calendar {
    box-shadow: 0px 0px 10px #ddd;
}

.main.calender .tavo-calendar__day span:hover {
    background-color: #b6bdc4;
    border-radius: 0;
    width: 40px;
    height: 26px;
}

.main.calender .tavo-calendar__days {
    background-color: #fff;
}

.main.calender .tavo-calendar__day_abs-today {
    border-bottom: 2px solid #b6bdc4;
    background-color: transparent;
}

span.range__counter {
    background-color: #3e8993;
    border-radius: 100%;
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.tab-content.dash-settin .avatar-upload .avatar-edit input~label {
    width: 125px;
    height: 125px;
    border-radius: 0;
}

.tab-content.dash-settin .avatar-upload .avatar-edit input+label:after {
    display: none;
}

span.pip {
    width: 87px;
    height: 87px;
    position: relative;
    margin-right: 20px;
    margin-bottom: 30px;
    display: inline-flex;
    flex-wrap: wrap;
}

span.pip img.imageThumb {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

span.remove_portfolioImgs {
    font-size: 0;
    position: absolute;
    top: -6px;
    right: -2px;
}

span.remove_portfolioImgs:before {
    content: "\f00d";
    font-family: "FontAwesome";
    position: absolute;
    right: 0;
    top: 0;
    font-size: 12px;
    background: #5a5a5a;
    border-radius: 100%;
    /* box-shadow: 0px 0px 10px #ccc; */
    width: 17px;
    height: 17px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: #fff;
    font-weight: 100;
}

span.remove_portfolioImgs:before:hover {
    opacity: 0.75;
}

.avtar-bottom .avatar-edit label {
    background-color: transparent !important;
    padding: 0 !important;
    background-position: center !important;
    background-size: contain !important;
    background-repeat: no-repeat !important;
    cursor: pointer !important;
    box-shadow: none !important;
    border: none !important;
}

/*form#shutterbee-schedule-form .range__input {
    background-color: #a9a9a9;
}*/

/*********19-01-2022*************/

form#shutterbee-profile-form .avatar-upload .avatar-preview {
    position: relative !important;
    top: 0;
    left: 0;
}

.tab-content.dash-settin .avatar-upload .avatar-preview {
    width: 87px;
    height: 87px;
}

.tab-content.dash-settin .avatar-upload .avatar-preview .close {
    background: #656565;
    border-radius: 100px;
    width: 17px;
    height: 17px;
    opacity: 1;
    color: #656565;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    top: -5px;
    right: -5px;
    font-size: 0px;
    font-weight: lighter !important;
}

.tab-content.dash-settin .avatar-upload .avatar-preview .close:before {
    content: "\f00d";
    font-family: "FontAwesome";
    position: absolute;
    right: 4px;
    top: 2px;
    font-size: 12px;
    background: transparent;
    border-radius: 100%;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-weight: 100;
}

form#shutterbee-profile-form .avatar-upload .avatar-edit label {
    background-position: top !important;
}

.wc-proceed-to-checkout .button {
    max-width: 300px;
    width: 300px;
}

.tab-content.dash-settin .avatar-upload.camera-upload .avatar-edit {
    position: absolute;
    bottom: -27px;
    left: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    margin: 0;
    display: inherit;
    width: auto;
    height: auto;
}

.tab-content.dash-settin .avatar-upload.camera-upload .avatar-edit input+label {
    width: 34px;
    height: 38px;
    border-radius: 100%;
}

.tab-content.dash-settin .avatar-upload.camera-upload {
    max-width: 125px !important;
}

.tab-content.dash-settin .avatar-upload.camera-upload .avatar-preview {
    margin-bottom: 0;
}

.tab-content.dash-settin .avatar-upload.camera-upload .avatar-edit input+label::after {
    display: block;
}

.woocommerce-cart .shop_table td a>img {
    width: 60px;
}

.avatar-preview .close {
    font-size: 0;
    position: absolute;
    top: -6px;
    right: -2px;
    opacity: 1;
    color: #5f5f5f;
}

/*.avatar-preview .close:before {
    content: "\f00d";
    font-family: 'FontAwesome';
    position: absolute;
    right: 0;
    top: 0;
    font-size: 13px;
    background: #fff;
    border-radius: 100%;
    box-shadow: 0px 0px 10px #ccc;
    width: 17px;
    height: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-weight: normal;
    padding-left: 1px;
}*/

.pip span.remove {
    font-size: 0;
    position: absolute;
    top: -6px;
    right: -2px;
}

.pip span.remove:before {
    content: "\f00d";
    font-family: "FontAwesome";
    position: absolute;
    right: 0;
    top: 0;
    font-size: 12px;
    background: #fff;
    padding: 0 0 0 1px;
    border-radius: 100%;
    box-shadow: 0px 0px 10px #ccc;
    width: 17px;
    height: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.upload-btn .clearfix {
    margin-bottom: 20px;
}

.profile-slider-content .left-right-fig .right-content input[type="checkbox"] {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    appearance: none;
    height: 100%;
    margin: 0;
    cursor: pointer;
}

.single-slider.selected {
    box-shadow: 0 0 0 3px #66d0da;
}

.event-booking-form-box .woocommerce-input-wrapper {
    width: 100%;
}

.event-booking-form-box .select2-container--default .select2-selection--single .select2-selection__rendered {
    line-height: 50px;
    font-weight: normal;
}

.event-booking-form-box .select2-container .select2-selection--single {
    height: 50px;
}

.event-booking-form-box .select2-container--default .select2-selection--single {
    border-radius: 0;
    border: 0;
}

.event-booking-form-box .select2-container--default .select2-selection--single .select2-selection__arrow {
    height: 50px;
}

.select2-dropdown {
    border: 0 !important;
}

section.thank-you.book-your-event.event-book-flow.event-product-page.event-booking-form.sett-pixel.after-margn {
    padding-top: 0 !important;
}

.woocommerce {
    padding-top: 109px;
}

body .woocommerce button.button.alt {
    background-color: #3e8993;
    border: 2px solid #3e8993;
}

body .woocommerce button.button.alt:hover {
    background: #fff;
    color: #3e8993;
    -webkit-box-shadow: 0px 10px 10px #4a4a4a;
    box-shadow: 0px 10px 10px #4a4a4a;
}

.tab-content.dash-settin .dash-setting-content .avatar-upload.avtar-bottom {
    flex-wrap: unset;
}

.eventt-booking-profiles .both-sliders {
    justify-content: space-between;
}

.eventt-booking-profiles .both-sliders .single-slider {
    width: 47.77%;
}

.bg-pt-events .past-events-two.access-images-album input#submit_upload {
    display: none;
}

.past-events-two .list-listing .trash {
    position: absolute;
    right: 10px;
    top: 5px;
}

.wpcf7-response-output {
    font-size: 14px;
}

.wpcf7-not-valid-tip {
    font-size: 14px;
}

td.product-thumbnail p {
    font-size: 15px;
}

#add_payment_method table.cart img,
.woocommerce-cart table.cart img,
.woocommerce-checkout table.cart img {
    width: 90px !important;
}

.thank-you.book-your-event.event-book-flow.event-product-page .product-page-cart-table table tr td {
    font-size: 15px;
}

input#coupon_code {
    font-size: 15px;
}

header .nav-link sup {
    background: #316d72;
    color: #fff;
    border-radius: 100%;
    padding: 0;
    font-weight: 600;
    font-size: 10px;
    border: 1px solid #3e8993;
    top: -8px;
    display: inline-block;
    width: 18px;
    height: 18px;
    left: -6px;
    line-height: 16px;
    text-align: center;
}

/*
header sup {
    display: none;
}
*/

.profile-slider-btns a.btn.btn-grey {
    height: 40px;
    font-size: 18px;
    padding: 0 20px;
    width: 250px;
    margin-bottom: 10px;
    line-height: 2.2;
    border: none;
}

.past-events-two .upload-btn-wrapper input[type="file"] {
    cursor: pointer;
    text-indent: -9999px;
    bottom: 0;
}

div.nsl-container .nsl-container-buttons {
    display: block;
    padding: 0;
}

div.nsl-container .nsl-button-default {
    color: #fff;
    display: flex;
    padding: 0 30px;
    width: 100%;
    font-family: "Montserrat", sans-serif;
    height: 58px;
}

div.nsl-container .nsl-button-svg-container {
    padding: 8px;
    display: flex;
    align-items: center;
    font-family: "Montserrat", sans-serif;
}

.nsl-container-buttons a {
    width: 100%;
    max-width: 100% !important;
    font-family: "Montserrat", sans-serif;
}

div.nsl-container-block[data-align="left"] .nsl-container-buttons {
    width: 100%;
}

div.nsl-container[data-align="left"] {
    align-items: center;
    display: flex;
    justify-content: center;
    width: 100%;
}

.nsl-button-label-container {
    font-family: "Montserrat", sans-serif !important;
    line-height: 3.6 !important;
    text-align: left !important;
    padding: 0 !important;
    text-transform: uppercase !important;
}

.thank-you td.product-name.details {
    min-width: 265px;
}

.thank-you figure.shutter.bbee.s_bimage {
    width: 100px !important;
    height: 100px !important;
    border-radius: 50%;
    overflow: hidden;
    margin-bottom: 0;
}

.thank-you figure.shutter.bbee.s_bimage img {
    width: 100% !important;
    height: 100% !important;
    object-fit: cover;
    object-position: center;
}

.my-events.extend-your-shutter-bee .extend-your-shutter-bee-list .profile-list .profile-list-single {
    width: 100%;
    display: flex;
    align-items: baseline;
    flex-wrap: unset;
    flex-direction: column;
}

.my-events.extend-your-shutter-bee .extend-your-shutter-bee-list .profile-list .profile-list-single form span img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.my-events.extend-your-shutter-bee .extend-your-shutter-bee-list .profile-list .profile-list-single form#extend-your-shutterbee-form {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.my-events.extend-your-shutter-bee .extend-your-shutter-bee-list .profile-list .profile-list-single form .form-group label {
    border: none;
    box-shadow: none;
    background-color: transparent;
    margin-top: -10px;
}

.page-template-preview .thank-you .btn {
    font-size: 18px;
    line-height: 1.4;
    padding: 7px 20px;
    height: 39px;
    font-weight: 500;
    margin-bottom: 10px;
}

.custom-radio-wrap form .form-group input:checked+label:after {
    content: "";
    font-family: "FontAwesome";
    position: absolute;
    top: 8px;
    right: 30px;
    background: #fff;
    padding: 0 5px;
    color: #3e8993;
    border-radius: 3px;
    widows: auto;
    height: auto;
    border-radius: 0px;
    left: unset;
    background-color: transparent;
    font-size: 20px;
}

.custom-radio-wrap form .form-group label:before {
    content: "";
    top: 11px;
    right: 11px;
}

.gj-modal {
    top: -80px !important;
}

.duration-amount {
    margin-bottom: 15px;
}

.my-events.extend-your-shutter-bee .extend-your-shutter-bee-list .profile-list .profile-list-single .profile-list-content {
    padding-left: 0;
}

.profile-list-content.review-bee .review-box .form-group textarea.form-control {
    border-radius: 4px;
}

.access-images-album .access-images-album-check-list .check_custom input[type=checkbox]+.check_box:before {
    content: "";
    background: transparent;
    border: 2px solid #fff;
}

.access-images-album .access-images-album-check-list .check_custom input[type=checkbox]:checked+.check_box:before {
    background-color: #fff;
}

.event-bk-profiles .eventt-booking-profiles .text-center.multiple_shutterb {
    position: fixed;
    bottom: 0;
    background: #fff;
    width: 100%;
    height: 66px;
    padding: 10px 0;
    left: 0;
    z-index: 9;
    box-shadow: 4px 2px 10px 2px #a9a9a9;
}

.event-bk-profiles .eventt-booking-profiles .text-center.multiple_shutterb button.btn.addToCart {
    height: 48px !important;
    font-size: 20px;
    padding: 0 30px;
    min-width: 170px;
    line-height: 2.3;
}

.sample-text{
    font-size: large;
    font-weight: 600;
    padding: 0 40px;
    height: 57px;
}

.dash-rqst.upcming {
    margin-bottom: 20px;
    margin-left: 20px;
}

.my-events.extend-your-shutter-bee .duration-amount .duration-amount-pay {
    align-items: end;
}

#myModal.modal .modal-dialog {
    max-width: 870px;
    padding: 0 10px;
    box-sizing: border-box;
}

.modal-body .main .slider {
    height: auto;
    margin-bottom: 0;
}

.modal-body .main .slider .slick-slide .media-wrap {
    height: 400px;
}

.modal-body .main .slider .slick-slide .media-wrap img,
.modal-body .main .slider .slick-slide .media-wrap video {
    height: 100% !important;
    width: 100%;
    object-fit: cover;
    border-radius: 0;
}

.modal-body .main .slider .slick-slide>div {
    display: flex;
}

.modal-body .main .slider .slick-slide .media-thumb-wrap {
    height: 100px;
    padding: 0 8px;
    cursor: pointer;
}

.modal-body .main .slider .slick-slide .media-thumb-wrap img,
.modal-body .main .slider .slick-slide .media-thumb-wrap video {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.modal-body .main .slider-nav .slick-list {
    margin: 0 -8px;
}

.modal-body .main .slider.slider-for {
    margin-bottom: 16px;
}

.modal-body button.close {
    position: absolute;
    top: -10px;
    right: -10px;
    background: #fff;
    opacity: 1;
    color: #000;
    height: 25px;
    width: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    font-size: 20px;
    line-height: 1;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
}

.loader-gif {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.5);
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
}

.loader-gif img {
    height: 50px;
    width: 50px;
}

.page-id-670 .modal-body .main .slider .slick-slide .media-wrap {
    height: 520px;
}

.page-id-670 #myModal.modal .modal-dialog {
    max-width: 1020px;
}

.page-template-attended-event .access-imagess figure {
    width: 100%;
}

.page-template-attended-event .access-imagess a.view-btn {
    display: inline-flex;
    margin: 5px 0 0 0;
    width: 100%;
    padding: 5px 10px;
    justify-content: center;
}

.access-images-album .access-images-album-check-list .check_custom .fa-lock {
    width: 35px;
    height: 30px;
    border-radius: 2px;
    background: #fff;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;
    top: 27px;
    right: 8px;
    z-index: 99;
}

.page-template-view .access-images-album>.row {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
}

.page-template-view .access-images-album>.row>div {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}


footer .footer-newsletter form p input.btn {
    height: 50px;
    line-height: 1;
    padding: 0 20px;
    margin-left: 30px;
}

.privacy-policy-content p,
.privacy-policy-content ul li {
    word-break: break-word;
}

.privacy-policy.contact-us form .form-group label {
    display: block;
}

section.my-events {
    overflow-x: hidden;
}

.refer-friend.access-images-album.support .form-group textarea.form-control {
    border-radius: 0;
}

.refer-friend.access-images-album input.form-control {
    border-radius: 0;
}

.privacy-policy.contact-us form .form-group label input,
.privacy-policy.contact-us form .form-group label textarea {
    border-radius: 0;
}

.my-account-nav .nav-tabs.navtab-head .nav-item.nav-link {
    padding: 0 25px !important;
}

.thank-you.book-your-event.event-book-flow.event-product-page.event-booking-form .event-booking-form-box form .term-and-policy .t-and-p .control-group {
    display: flex;
    align-items: flex-start;
}

.my-events.extend-your-shutter-bee .extend-your-shutter-bee-list .profile-list .profile-list-single form .form-group label span {
    width: 120px !important;
    height: 130px !important;
    display: block !important;
}

.profile-slider-content .left-right-fig .left-img figure,
.event-booking-profile2 .event-b-profile2 .left figure {
    border-radius: 50%;
    overflow: hidden;
}

/* .home-banner{
        background-image: url('http://shutter-bee-dev.customerdevsites.com/wp-content/uploads/2023/02/mobile.png') !important;
    } */

.home-banner.mobile__view {
    display: none;
}

/* 18 april 2023 */

.review-silder .slick-list {
    margin: 0 -12px;
}

.review-silder .slick-slide {
    padding: 0 12px;
}

/* 18 april 2023 */

.page-id-670 .main_ctnt .modal-dialog {
    max-width: 750px !important;
}

.main_ctnt .modal-dialog .main_video video,
.main_ctnt .modal-dialog figure {
    width: 100%;
    height: 550px;
    margin: 0;
}

.main_ctnt .modal-dialog figure img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

i.fa.fa-times-circle-o {
    color: #3e8993;
}

.main_ctnt .close {
    color: #fff;
    opacity: 1;
}

.main_ctnt .close_video {
    color: #000;
    z-index: 999;
}

button.btn_row {
    background: transparent;
    border: transparent;
    color: #3e8993;
    margin: 0 5px;
    font-weight: 600;
}

button.btn_row:hover {
    color: #000;
}

#sb_instagram .sbi_type_video .sbi_playbtn,
#sb_instagram .sbi_type_carousel .sbi_playbtn {
    transform: translateX(-50%) !important;
    margin-left: 0 !important;
}

.instagram .heading h3 span {
    background: #fff;
    padding: 0 15px;
    position: relative;
    z-index: 4;
}

#sb_instagram .sbi_follow_btn svg {
    width: 30px;
}

@media screen and (max-width: 1499px) {
    .access-imagess table th:first-child {
        width: 40%;
    }
    input#coupon_code {
        width: 130px;
        font-size: 15px;
        height: 34px;
    }
    a.btn.checkout-button.button.alt.wc-forward {
        font-size: 17px;
        height: 45px;
    }
    .profile-slider-content .lower-content p {
        font-size: 14px;
    }
    .profile-slider-content .left-right-fig .right-content h6 {
        font-size: 14px;
    }
    .profile-slider-content .left-right-fig .right-content p {
        font-size: 14px;
    }
    .profile-slider-content .left-right-fig .left-img figure {
        width: 70px;
        height: 70px;
    }
    .profile-slider-content .left-right-fig .right-content {
        padding-left: 12px;
    }
    .profile-slider-content .left-right-fig .right-content input[type="checkbox"] {
        margin-top: 0px;
    }
    label {
        font-size: 16px;
    }
    .home-login-modal .modal-body .form-group input.form-control {
        height: 50px;
    }
    .form-group p {
        font-size: 14px;
        margin-top: 5px;
    }
    .modal-content form .form-group .gj-timepicker.gj-timepicker-md.gj-unselectable input#input {
        height: 50px;
    }
    .modal-content .gj-timepicker.gj-timepicker-md.gj-unselectable {
        height: 50px;
    }
    .modal-content form .form-group .gj-timepicker.gj-timepicker-md.gj-unselectable input#input2 {
        height: 50px;
    }
    .home-login-modal .modal-content .event-booking-form-btn button.btn {
        height: 46px;
        font-size: 18px;
        width: 180px;
    }
}

@media screen and (max-width: 1499px) {
    .refer-friend.access-images-album.support {
        max-width: initial;
    }
    div.nsl-container .nsl-button-default {
        padding: 0 10px !important;
    }
    .profile-slider-btns a.btn.btn-grey {
        height: 38px !important;
        font-size: 16px !important;
        padding: 0 15px !important;
        width: 200px !important;
        margin-bottom: 10px !important;
    }
    .access-images-album cite {
        max-width: 600px;
    }
    header .navbar-nav li a {
        font-size: 14px;
    }
    .dash-rqst.upcming .rqst-accept-decline-btn a.btn-grey {
        background: #979797;
        font-weight: 500 !important;
        height: 34px !important;
        font-size: 15px !important;
    }
    .upload-btn-wrapper {
        height: 34px;
        font-size: 15px;
        top: 12px;
    }
    .dash-rqst .rqst-accept-decline-btn button.btn {
        font-size: 15px;
        height: 34px;
        padding: 0 12px;
    }
    .upload-btn-wrapper .upload-btn img {
        margin-top: 10px;
    }
}

@media screen and (max-width:1499px) {
    .access-imagess figure {
        width: 248px;
        height: 217px;
    }
    .access-imagess table th {
        font-size: 15px;
    }
    .access-imagess table td {
        font-size: 15px;
    }
    .access-imagess table td ul li figure {
        width: 90px;
        height: 90px;
    }
    .access-imagess table td ul li:last-child {
        font-size: 15px;
    }
    .upload-btn-wrapper {
        height: auto;
    }
    .past-events-two .short-list-btn {
        margin-bottom: 15px;
    }
    .thank-content.book-event-design.event-booking-cart-flow.text-center {
        margin-top: 120px;
    }
}

@media screen and (max-width: 1499px) {
    .view-gallery .form-group {
        height: 370px;
    }
}

@media screen and (max-width:767px) {
    .gj-textbox-md {
        font-size: 14px !important;
    }
    .my-events.extend-your-shutter-bee.tip-your-bee .profile-list figure .list-profile-content {
        position: absolute;
        left: 110px;
        top: 0;
        width: 100%;
        min-width: 150px;
    }
    td.product-name.details {
        display: inline-block;
        width: 100%;
        clear: both;
    }
    .thank-you.book-your-event.event-book-flow.event-product-page .product-page-cart-table table tr td.product-remove,
    .thank-you.book-your-event.event-book-flow.event-product-page .product-page-cart-table table tr td.product-thumbnail {
        width: 50%;
        display: inline-block !important;
        float: right;
        background: #f3f3f3;
        min-height: 121px;
        display: flex !important;
        align-items: center;
    }
    .thank-you.book-your-event.event-book-flow.event-product-page .product-page-cart-table table tr td.product-thumbnail {
        position: relative;
    }
    .thank-you.book-your-event.event-book-flow.event-product-page .product-page-cart-table table tr td.product-thumbnail img {
        border-radius: 50% !important;
    }
    .thank-you.book-your-event.event-book-flow.event-product-page .product-page-cart-table table tr td.product-thumbnail:after {
        content: "";
        background: #f3f3f3;
        width: 100%;
        height: 100%;
        left: -100%;
        top: 0;
        z-index: -1;
        position: absolute;
    }
    form .form-group select {
        font-size: 14px !important;
    }
    .thank-you.book-your-event.event-book-flow.event-product-page .product-page-cart-table table tr td {
        display: block !important;
        text-align: center !important;
    }
    .woocommerce-page table.shop_table_responsive tr td.product-name.details::before,
    .woocommerce-page table.shop_table_responsive tr td.product-thumbnail::before {
        display: none;
    }
    .woocommerce-page table.shop_table_responsive tr td.product-name.details .cart-name {
        margin-top: -10px;
        font-weight: 700;
        margin-bottom: 11px;
        display: inline-block;
        padding-left: 50%;
    }
}

@media screen and (max-width: 1199px) {
    header.sticky {
        height: auto;
    }
    header.sticky a.navbar-brand {
        padding: 0;
    }
    .access-imagess figure {
        width: 205px;
        height: 217px;
        margin-bottom: 15px;
    }
    .my-events {
        padding-top: 140px;
    }
    .my-events.extend-your-shutter-bee .duration-amount .duration-amount-pay .form-group label {
        font-size: 15px;
    }
    .my-events.extend-your-shutter-bee .extend-your-shutter-bee-list .profile-list .profile-list-single .profile-list-content {
        padding-left: 8px;
        padding-right: 17px;
    }
    .custom-radio-wrap {
        width: 150px;
    }
    .my-events.extend-your-shutter-bee .extend-your-shutter-bee-list .profile-list .profile-list-single form span img {
        width: 103px;
        height: 100px;
    }
    .access-images-album .access-images-album-check-list .form-group {
        width: 195px;
        height: 160px;
    }
}

@media screen and (max-width: 991px) {
    header ul.navbar-nav.help-ul li {
        min-height: 36px;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #565656;
        width: 100%;
        margin: 8px 0;
    }
    header ul.navbar-nav.help-ul li a i {
        font-size: 16px;
    }
    header ul.navbar-nav.help-ul li:hover:after {
        width: 100%;
    }
    .my-events {
        padding-top: 130px;
    }
    .my-events.extend-your-shutter-bee .extend-your-shutter-bee-list .profile-list .profile-list-single {
        flex-direction: column;
    }
    .short-list-btn .upload-btn-wrapper {
        height: 38px;
    }
    .main-header {
        background: #fff;
        position: relative;
        width: 100%;
        left: 0;
        padding: 0 20px 25px 20px;
        right: 0;
    }
    section.thank-you.book-your-event.event-book-flow.event-product-page.event-booking-form {
        padding-top: 120px;
    }
    .thank-you h1 {
        font-size: 2rem;
    }
    .thank-you.book-your-event .thank-content.book-event-design h1 {
        margin-bottom: 50px;
    }
}

@media screen and (max-width: 767px) {
    .home-banner {
        display: none;
    }
    .home-banner.mobile__view {
        display: block;
        background-attachment: inherit;
    }
    .home-banner {
        padding: 92px 0 !important;
    }
    table.shop_table.woocommerce-cart-form__contents tr.subtotal.ggg td:first-child::before {
        display: none;
    }
    footer .footer-newsletter form .form-group {
        width: 100% !important;
    }
    footer .footer-newsletter form {
        text-align: left;
    }
    footer .footer-newsletter form p input.btn {
        height: 45px;
        max-width: 600px;
        margin-left: 0 !important;
        width: 100%;
    }
    .form-group.f-sign-check-row {
        margin: 10px 0 0 0;
    }
    .form-group.f-sign-check-row .wpcf7-list-item {
        margin-left: 0 !important;
    }
    .extend-your-shutter-bee-list .profile-list figure {
        width: 110px !important;
        height: 120px !important;
        display: flex;
    }
    .thank-you figure.shutter.bbee.s_bimage {
        width: 70px !important;
        height: 70px !important;
    }
    .my-events.extend-your-shutter-bee.tip-your-bee .profile-list .profile-list-content {
        margin-top: 25px;
    }
    .my-events.extend-your-shutter-bee.tip-your-bee .profile-list figure .list-profile-content {
        padding-left: 10px;
    }
    .access-imagess figure {
        width: 100%;
        height: auto;
    }
    div.nsl-container .nsl-button-default {
        padding: 0 0px;
    }
    .my-events.extend-your-shutter-bee .duration-amount .duration-amount-pay .form-group select.form-control {
        background-size: 12px;
    }
    .pay-btn {
        text-align: center;
    }
    .my-events.extend-your-shutter-bee .extend-your-shutter-bee-list .profile-list .profile-list-single form#extend-your-shutterbee-form {
        flex-direction: column;
    }
    .access-images-album .access-images-album-check-list .form-group {
        width: auto;
        height: 330px;
    }
    .dash-rqst.upcming .rqst-accept-decline-btn a.btn-grey {
        font-size: 14px !important;
        margin: 0px;
        width: 100%;
    }
    .upload-btn-wrapper {
        font-size: 14px;
        position: inherit;
        width: 100%;
        display: flex;
        justify-content: center;
        margin: 0px 0px 10px 0;
    }
    .thank-you.book-your-event.event-book-flow.event-product-page .product-page-cart-table table tr td {
        text-align: left !important;
    }
    .woocommerce table.shop_table_responsive tr td::before,
    .woocommerce-page table.shop_table_responsive tr td::before,
    .woocommerce td.product-name dl.variation dt {
        width: 50%;
    }
    .thank-you .bee_name {
        width: 50%;
        max-width: inherit;
        margin-right: 0.25em;
    }
    .thank-you .cart-name {
        width: 50%;
        padding-left: 0 !important;
        margin: 0 !important;
        font-weight: 500 !important;
        font-size: 12px;
    }
}

@media screen and (max-width:600px) {
    /* .payment_box.payment_method_stripe .form-row.form-row-first,
    .payment_box.payment_method_stripe .form-row.form-row-last {
        width: 100%;
    } */
    .woocommerce-checkout #payment ul.payment_methods .stripe-card-group {
        position: relative;
        display: inline-block;
        width: 100%;
    }
    div#stripe-card-element,
    div#stripe-exp-element,
    div#stripe-cvc-element {
        width: 100%;
        display: inline-block;
    }
    .woocommerce form .form-row label,
    .woocommerce-page form .form-row label {
        width: 100%;
    }
    .woocommerce-checkout #payment div.form-row {
        padding: 0 !important;
    }
    .woocommerce-checkout #payment ul.payment_methods li .stripe-credit-card-brand {
        height: 17px;
    }
}

form.woocommerce-cart-form {
    overflow-x: scroll;
}

.thank-you.book-your-event.event-book-flow.event-product-page .product-page-cart-table table {
    /* width: auto;
    min-width: 1130px; */
}

.thank-you .shop_table_responsive dl.variation dd {
    display: flex;
}

.thank-you.book-your-event.event-book-flow.event-product-page.event-booking-form .event-booking-form-box form .form-group input.form-control.event-location {
    padding-right: 50px;
}

.thank-you p.form-row.woocommerce-SavedPaymentMethods-saveNew.woocommerce-validated label {
    width: calc(100% - 30px) !important;
    margin-bottom: 0;
}

.payment_box.payment_method_stripe p.form-row.woocommerce-SavedPaymentMethods-saveNew.woocommerce-validated input {
    margin-right: 8px !important;
}

.payment_box.payment_method_stripe p.form-row.woocommerce-SavedPaymentMethods-saveNew.woocommerce-validated {
    align-items: baseline;
}

.my-events.extend-your-shutter-bee .duration-amount .duration-amount-pay .form-group select.form-control,
.my-events.extend-your-shutter-bee .duration-amount .duration-amount-pay .form-group input.form-control {
    border-radius: 0;
}

.my-events.extend-your-shutter-bee .duration-amount .duration-amount-pay .form-group .pay-btn .btn {
    border-radius: 0;
    height: 60px;
}

.my-events.extend-your-shutter-bee .extend-your-shutter-bee-list .profile-list .profile-list-single .profile-list-content {
    margin-top: 20px;
}

.my-events.extend-your-shutter-bee .extend-your-shutter-bee-list .profile-list {
    justify-content: flex-start;
}

.gj-textbox-md {
    color: #505050 !important;
    font-family: "Montserrat", sans-serif !important;
}

.extend-your-shutter-bee-list .profile-list figure {
    position: relative;
}

.extend-your-shutter-bee-list .profile-list figure .check-Box {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.extend-your-shutter-bee-list .profile-list figure .check-Box span {
    position: absolute;
    right: 28px;
    top: 0;
    display: inline-block;
}

/*
    .extend-your-shutter-bee-list .profile-list figure .check-Box span:before {
        content: "";
        width: 28px;
        height: 28px;
        background: #fff;
        position: absolute;
        top: 0;
        left: 0;
    }
*/

.extend-your-shutter-bee-list .profile-list figure .check-Box span:before {
    content: "";
    width: 28px;
    height: 28px;
    background: #fff;
    position: absolute;
    top: 0;
    left: 0;
    border: 1px solid #3e8993;
    border-radius: 6px;
    background-color: #303030;
}

.extend-your-shutter-bee-list .profile-list figure .check-Box input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9;
    opacity: 0;
    cursor: pointer;
}

.extend-your-shutter-bee-list .profile-list figure .check-Box input:checked+span:after {
    content: "";
    display: block;
    position: absolute;
    top: 6px;
    left: 10px;
    width: 9px;
    height: 15px;
    border: solid #3e8993;
    border-width: 0 4px 4px 0;
    transform: rotate(45deg);
}

.event-booking-profile2 .event-b-profile2 .left figure img,
.profile-slider-content .left-right-fig .left-img figure img {
    width: 100% !important;
    object-fit: cover;
    object-position: center;
}

.extend-your-shutter-bee-list .profile-list figure img {
    width: 100%;
}

.blog .blog-pagination ul {
    box-shadow: 0px 2px 25px 0px #ccc;
    padding: 0 30px;
}

/*    30 septemebr 2022*/

.timepicker-content .btn {
    min-width: auto;
}

input#event_start_time,
input#shutterb_start_time {
   /* background-image: url("/images/clock.svg");*/
    background-size: 22px;
    background-position: calc(100% - 10px) 50%;
    background-repeat: no-repeat;
}

.event-booking-form-btn .btn {
    background-color: #3e8993;
    color: #fff;
    font-size: 18px;
    padding-top: 0px;
    padding-bottom: 0px;
}

.event-booking-form-btn .btn:hover,
.event-booking-form-btn .btn:focus {
    background-color: #fff;
    color: #3e8993;
}

/*    02 october 2022*/

.training-main-wrap {
    padding: 20px;
    background-color: #f7f7f7;
}

.training-wrap {
    text-align: center;
    margin-bottom: 30px;
}

.training-wrap h5 {
    color: #3b3b3b;
}

.training-wrap .traing-media-wrap {
    margin: 0;
    height: 200px;
}

.training-wrap .traing-media-wrap .traing-media {
    height: 100%;
    width: 100%;
    object-fit: cover;
    display: block;
}

.my-events.training-space .training-main-wrap .row {
    margin-bottom: -10px;
}

div .btn {
    background-color: #3e8993;
    color: #fff;
}

.meet-shutterbz .meet-bz {
    align-items: flex-start !important;
}

.home-banner,
.how-we-work {
    background-position: top center !important;
}

/*    media starts here*/

.toltip_tp[data-title]:not([data-title=""]) {
    position: relative;
    display: inline-block;
    background: transparent;
    border: transparent;
    margin-left: 5px;
}

.toltip_tp[data-title]:not([data-title=""])::before,
.toltip_tp[data-title]:not([data-title=""])::after {
    position: absolute;
    display: block;
    top: -5px;
    left: 50%;
    z-index: 1070;
    opacity: 0;
    transition: opacity 0.15s linear;
}

.toltip_tp[data-title]:not([data-title=""])::before {
    content: "";
    transform: translateX(-50%);
    border-width: 5px 5px 0 5px;
    border-style: solid;
    border-color: #000 transparent transparent transparent;
}

.toltip_tp[data-title]:not([data-title=""])::after {
    content: attr(data-title);
    transform: translateX(-50%) translateY(-100%);
    line-break: auto;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    word-wrap: normal;
    white-space: normal;
    font-size: 12px;
    max-width: 200px;
    padding: 3px 8px;
    color: #fff;
    text-align: center;
    background-color: #000;
    border-radius: 0px;
    min-width: 150px;
    pointer-events: none;
}

.toltip_tp[data-title]:not([data-title=""]):hover::before,
.toltip_tp[data-title]:not([data-title=""]):hover::after {
    opacity: 0.9;
}

.mobile_video {
    display: none;
}

@media screen and (min-width: 1500px) {
    .container {
        max-width: 1470px !important;
    }
}

@media (min-width:1400px) and (max-width:1499.98px) {
    footer .footer-newsletter form p input.btn {
        height: 45px;
        line-height: 1;
        padding: 0 20px;
        margin-left: 0;
    }
}

/*@media screen and (max-width:1499.98px) {
    footer .footer-newsletter form .form-group {
        width: 54%;
    }
    .home-banner {
        padding: 150px 0 !important;
    }
    .my-events.extend-your-shutter-bee .duration-amount .duration-amount-pay .form-group .pay-btn .btn,
    .my-events.extend-your-shutter-bee .duration-amount .duration-amount-pay .form-group input.form-control {
        height: 44px;
    }
}*/

@media only screen and (max-width:1399.98px) {
    footer .footer-newsletter form p input.btn {
        height: 45px;
        padding: 0 30px;
        /* margin: 0; */
    }
    footer .footer-newsletter form p input.btn {
        margin-left: 10px;
    }
    .my-events .my-events-sidebar ul li.active a:after {
        top: -4px;
    }
}

@media only screen and (max-width:1199.98px) {
    footer .footer-newsletter form p input.btn {
        height: 45px;
    }
    .eventt-booking-profiles .both-sliders .single-slider {
        width: 48.77%;
    }
    .profile-slider-content {
        width: calc(100% - 212px);
        padding: 20px 20px;
        max-width: 282px;
    }
    .eventt-booking-profiles .both-sliders .single-slider .profile-slider.slick-initialized.slick-slider .profile-slider-box figure {
        width: 210px;
    }
    .eventt-booking-profiles .both-sliders .single-slider .profile-slider.slick-initialized.slick-slider {
        width: 210px;
    }
}

@media only screen and (max-width: 991px) {
    .eventt-booking-profiles .both-sliders .single-slider {
        width: 100%;
    }
    .eventt-booking-profiles .both-sliders .single-slider .profile-slider.slick-initialized.slick-slider div {
        margin-left: 0;
    }
    .eventt-booking-profiles .both-sliders .single-slider .profile-slider.slick-initialized.slick-slider {
        width: 330px;
    }
    .profile-slider-content {
        width: calc(100% - 212px);
        padding: 20px 20px;
        max-width: 360px;
    }
    .eventt-booking-profiles .both-sliders .single-slider .profile-slider.slick-initialized.slick-slider .profile-slider-box figure {
        width: 100%;
    }
    .desktop_video {
        display: none;
    }
    .mobile_video {
        display: block;
    }
}

@media only screen and (max-width: 767.98px) {
    /* .thank-you {
        margin-top: 40px;
    } */
    .home-banner {
       /* background-image: url(../images/mobile-banner.png) !important;*/
        background-position: 35% 100% !important;
        background-size: cover;
        background-repeat: no-repeat;
        height: 380px;
    }
    footer .footer-newsletter form p {
        position: relative;
    }
    footer .footer-newsletter form span.wpcf7-spinner {
        position: absolute !important;
        top: 50%;
        transform: translateY(-50%);
    }
    .thank-you.book-your-event.event-book-flow.event-product-page.event-booking-form .event-booking-form-box form .term-and-policy .t-and-p label {
        padding-left: 10px;
    }
    .my-account-nav .nav-tabs.navtab-head .nav-item.nav-link {
        padding: 0 15px !important;
        font-size: 13px;
    }
    .my-events.extend-your-shutter-bee .duration-amount .duration-amount-pay {
        flex-wrap: wrap;
    }
    .my-events.extend-your-shutter-bee .duration-amount .duration-amount-pay .form-group {
        margin: 0 0 20px 0;
    }
    .my-events.extend-your-shutter-bee .duration-amount .duration-amount-pay .form-group:last-child {
        margin: 0;
    }
    .home-login-modal {
        padding: 20px 10px;
    }
    /* .home-login-modal .modal-dialog{
        margin: 0;
    } */
    .home-login-modal .modal-dialog .modal-content form .form-group:last-child {
        margin-bottom: 10px !important;
    }
    .home-login-modal .modal-dialog .modal-content .forgot-password p {
        font-size: 12px;
    }
    div.nsl-container .nsl-button-default {
        align-items: center;
    }
    div.nsl-container .nsl-button-default div.nsl-button-label-container {
        font-size: 14px;
    }
    .modal-body .login-click-here p a {
        display: block;
    }
    .profile-list-content.review-bee .rate:not(:checked)>label {
        width: 25px;
    }
    .profile-list-content.review-bee .rate:not(:checked)>label:before {
        font-size: 25px;
    }
    .access-images-album .access-images-album-heading-list .left-list ul li {
        margin: 0 19px 0 0px;
    }
    .access-images-album .access-images-album-heading-list .left-list ul li a {
        white-space: nowrap;
    }
    #myModal.modal .modal-dialog {
        margin: 20px auto;
    }
    .home-login-modal div.nsl-container .nsl-button-svg-container {
        flex: 0 0 auto;
        padding: 4px;
        display: flex;
        align-items: center;
    }
    .home-login-modal div.nsl-container svg {
        height: 20px;
        width: 20px;
    }
    .home-login-modal .modal-dialog {
        margin: auto;
    }
    .profile-slider-content {
        width: 100%;
        max-width: 100%;
        min-height: 320px;
    }
    .eventt-booking-profiles .both-sliders .single-slider .profile-slider.slick-initialized.slick-slider {
        width: 100%;
    }
    .past-events-two .upload-btn-wrapper {
        flex-direction: column;
        height: auto;
        max-width: 205px;
        margin: 0 auto;
    }
    .past-events-two .upload-btn-wrapper form {
        margin-bottom: 10px;
    }
    .past-events-two .short-list-btn button.btn,
    .past-events-two .upload-btn-wrapper form {
        width: 100%;
    }
}

@media screen and (max-width: 480px) {
    .home-login-modal div.nsl-container .nsl-button-default div.nsl-button-label-container {
        margin: 0 0 0 4px;
        padding: 10px 0;
        font-size: 14px;
        letter-spacing: 0;
    }
    .home-login-modal .modal-content {
        padding: 0 12px;
    }
    .home-login-modal .modal-content .modal-header button.close {
        right: -7px;
    }
}

@media screen and (max-width: 757px) {
    .reviews .slick-prev {
        left: -5px;
    }
    .thank-you .thanku-btns a.btn.new-booking {
        margin-right: 0;
    }
    .reviews .slick-next {
        right: -5px;
    }
    .reviews .testimonial-slider .review-box {
        padding: 0 15px;
    }
    .woocommerce .cart-collaterals .cart_totals,
    .woocommerce-page .cart-collaterals .cart_totals .wc-proceed-to-checkout {
        flex-direction: column;
    }
    .thank-you.book-your-event.event-book-flow.event-product-page .product-page-cart-table table {
        min-width: 100%;
        width: 100%;
    }
    .woocommerce-page .cart-collaterals .cart_totals .wc-proceed-to-checkout .btn {
        margin-bottom: 10px !important;
        margin-left: 0 !important;
    }
    .entry-content td.product-name.details dt {
        text-align: left;
    }
    .wc-proceed-to-checkout .button {
        width: 100%;
    }
    .thank-you.book-your-event.event-book-flow.event-product-page .product-page-cart-table table tr td {
        padding: 20px 25px 20px 25px;
    }
    section.thank-you.book-your-event.event-book-flow.event-product-page.event-booking-form.sett-pixel.after-margn {
        overflow-x: hidden;
    }
    .thank-you .taxes-table table tr td {
        color: #3d3d3d;
        font-weight: 600;
        padding: 5px 20px 5px 20px;
        font-size: 17px;
    }
    .thank-you.book-your-event.event-book-flow.event-product-page.event-booking-form .event-booking-form-box {
        margin-top: 20px;
    }
    .thank-you .event-booking-payment ul li {
        font-size: 16px;
    }
    .thank-you .event-booking-payment figure {
        width: 90px;
        height: 90px;
        max-width: 90px;
    }
    .thank-you .woocommerce-checkout #payment ul.payment_methods {
        padding: 0;
    }
    .thank-you.book-your-event.event-book-flow.event-product-page.event-booking-form .event-booking-form-box form .form-group input.form-control {
        display: flex;
        align-items: center;
        line-height: 45px;
        text-align: left;
    }
    input::-webkit-date-and-time-value {
        text-align: left !important;
    }
    .thank-you .eventt-booking-profiles {
        overflow-x: hidden;
    }
    .thanku-btns {
        flex-direction: column;
    }
    .thank-you .thanku-btns a.btn.new-booking {
        margin-bottom: 15px;
    }
    .event-booking-profile2 .event-b-profile2 .left figure {
        width: 100px;
        height: 100px;
    }
    .event-booking-profile2 .event-book-profile-gallery figure {
        margin-left: auto;
        margin-right: auto;
    }
    .thank-you.book-your-event.event-book-flow.event-product-page .product-page-cart-table table tr td.product-remove {
        padding-left: 0 !important;
    }
}

.event-booking-profile2 .event-b-profile2 .right .iphone {
    align-items: center;
}

@media screen and (max-width: 575px) {
    .event-booking-profile2 {
        padding: 20px 15px;
    }
    .event-booking-profile2 .event-b-profile2 .left figure {
        width: 65px;
        height: 65px;
    }
    .event-booking-profile2 .event-b-profile2 .left .britney-data h6,
    .event-booking-profile2 .event-b-profile2 .right h6 {
        font-size: 15px;
    }
    .event-booking-profile2 .event-b-profile2 .left .britney-data {
        padding: 15px 8px 0 10px;
        width: calc(100% - 65px);
    }
    .event-booking-profile2 .event-b-profile2 .right figure {
        width: 35px;
        height: 40px;
    }
    .event-booking-profile2 .event-b-profile2 .right .iphone p {
        padding-left: 7px;
        min-width: 71px;
    }
    .event-booking-profile2 .profile-content {
        padding-right: 0;
        padding-left: 0;
    }
}

.thank-you.book-your-event .thank-content.book-event-design ul li.active:nth-of-type(2):before {
    border: none;
}

@media screen and (max-width: 400px) {
    .thank-you #order_review label {
        font-size: 12px !important;
    }
    .thank-you.book-your-event.event-book-flow.event-product-page .product-page-cart-table table tr td button {
        font-size: 13px;
        line-height: 19px;
    }
    .thank-you.book-your-event.event-book-flow.event-product-page .product-page-cart-table table tr td {
        padding: 20px 20px 20px 20px;
    }
}

.selected .profile-slider-btns .reserve-btn {
    background: #fff;
    color: #3e8993;
    /* border: 2px solid #3e8993; */
    box-shadow: 0px 10px 10px #4a4a4a;
}

@media (pointer:coarse) {
    .reserve-btn:hover {
        background: #3e8993;
        color: #fff;
        box-shadow: none;
    }
}

header ul.navbar-nav.help-ul li:after {
    bottom: 0;
    left: 0;
    height: 1px;
}

.my-events.extend-your-shutter-bee.tip-your-bee .profile-list .profile-list-content.no_paytip .select-tip a {
    background-color: #ccc;
    border-color: #ccc;
    opacity: 0.5;
    pointer-events: none;
}

.my-events.extend-your-shutter-bee.tip-your-bee .profile-list .profile-list-content.no_paytip .card .card-header {
    pointer-events: none;
    background-color: #ccc;
    opacity: 0.5;
}

.popover .keyboard-input .title tbody tr {
    margin-bottom: 0;
}

.popover .keyboard-input .btn,
.popover .keyboard-input .form-control,
.popover-body .btn {
    height: auto;
    margin: 5px 0;
    border: 2px solid #3e8993;
    padding: 4px 6px;
}

.popover .keyboard-input .fas:before {
    font-family: 'FontAwesome';
    font-style: normal;
    font-size: 15px;
}

.popover .keyboard-input .btn:hover {
    color: #0f7864;
    text-decoration: none;
}

.popover-body .timepicker-content .invisible {
    display: none;
}

.corporate-sec .heading {
    margin-bottom: 33px;
}

/* .corporate-sec h3 {
        font-size: 14px;
        font-weight: 400;
    } */

.corporate-sec p {
    color: #fff;
    /* font-size: 14px; */
    font-weight: 400;
    text-align: left;
}

.corporate-sec .btn {
    margin-top: 5px;
    font-size: 18px;
    font-weight: 700;
    line-height: 48px;
    height: 50px;
    padding: 0 40px;
    border-radius: 0;
    margin-bottom: 20px;
}

.book-new-popup .modal-dialog {
    max-width: 650px;
}

.book-new-popup .modal-content {
    padding: 50px 35px;
    background: rgba(74, 70, 70, 0.8);
    border-radius: 0;
}

.book-new-popup .modal-content a {
    background: #fff;
    display: inline-block;
    width: 100%;
    margin-bottom: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px 0;
    border: 7px solid #fff;
    color: #000;
}

.book-new-popup .modal-content a:hover {
    border-color: #3e8993;
}

.book-new-popup .modal-content a:last-child {
    margin-bottom: 0;
}

.book-new-popup .modal-content figure {
    margin-left: 70px;
    margin-bottom: 0;
    height: 65px;
}

.book-new-popup .modal-content figure img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.booking-request-section {
    margin-top: 100px;
    padding-bottom: 80px;
}

.booking-request-section .booking-request-cnt {
    max-width: 1000px;
    margin: auto;
}

.thank-you.book-your-event.event-book-flow.event-product-page.event-booking-form.booking-request-section {
    padding-top: 50px;
}

.booking-request-section .booking-request-title {
    margin-bottom: 50px;
}

.booking-request-section .booking-request-title h3 {
    font-size: 24px;
    margin-bottom: 70px;
    text-align: center;
    color: #000;
}

.booking-request-section .booking-request-title p {
    color: #000;
    font-size: 16px;
}

.booking-request-section .btn {
    font-size: 18px;
    font-weight: 700;
    line-height: 48px;
    height: 50px;
    padding: 0 40px;
    border-radius: 0;
}

.clr-blue{
    color: #3e8993;
}

.checkbox-size{
    width: 20px !important;
    height: 20px !important;
}

.av-submitted {
    width: 100% !important;
  }