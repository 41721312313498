.black-drop{
    width: 100%;
    height: 100%;
    background:rgba(0,0,0,0.7);
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 1000;
    overflow: auto;

    .modal-forms-con{
        height: 100vh;
        overflow-y: auto;
        overflow-x: visible;
    }
    
}

.no-black-drop{
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    z-index: 1000;
    overflow: auto;

    .modal-forms-con{
        overflow-y: auto;
        overflow-x: visible;
    }
    
}

.border-color-grey{
    border: 1px solid grey;
}