footer {
  background: #fff;
  text-align: center;
  padding: 50px 0 15px 0;

  position: relative;
  left: 0;
  bottom: 0;
  width: 100%;
  @include breakpoint(desktop) {
    padding: 40px 0 10px 0;
  }
  .footer-logo {
    margin: auto;
    width: 260px;
    @include breakpoint(desktop) {
      margin-bottom: 30px;
    }
  }
  .footer-links {
    margin-bottom: 20px;
    font-size: 13px;
    ul {
      li {
        margin: 0 15px 0 15px;
        position: relative;
        &::after {
          content: "";
          background: #fff;
          width: 0px;
          height: 2px;
          display: block;
          transition: width 0.3s;
          position: absolute;
        }
        &:hover {
          &::after {
            width: 40px;
          }
        }
        a {
          color: #000;
          &:hover {
            color: #333333;
            text-shadow: 0px 0px 1px #dcdcdc;
          }
        }
      }
    }
  }
  .footer-newsletter {
    width: 100%;
    max-width: 600px;
    margin: auto;
    margin-bottom: 25px;
    h6 {
      color: #3e8993;
      font-size: 20px;
      margin-bottom: 20px;
    }
    form {
      display: flex;
      justify-content: center;
      .form-group {
        //  width: 50%;
        input.form-control {
          border-radius: 5px !important;
          height: 50px !important;
          width: 100%;
        }
      }
      button.btn {
        height: 50px;
        font-size: 18px;
        padding: 0 50px;
        margin: 0 0px 0 20px;
      }
    }
  }
  .footer-social-links {
    margin-bottom: 30px;
    ul {
      display: flex;
      justify-content: center;
      li {
        margin: 0 12px 0 12px;
        a {
          color: #000;
          transition: all 0.3s;
          .fa-facebook-f {
            transition: all 0.3s;
            &:hover {
              color: #4267b2;
            }
          }
          .fa-twitter {
            transition: all 0.3s;
            &:hover {
              color: #1da1f2;
            }
          }
          .fa-instagram {
            transition: all 0.3s;
            &:hover {
              color: #405de6;
            }
          }
          .fa-pinterest {
            transition: all 0.3s;
            &:hover {
              color: #405de6;
            }
          }
        }
      }
    }
  }
  .footer-copyright {
    p {
      font-size: 13px;
      margin-bottom: 0;
    }
  }
    .footer-bottom {
    p {
      font-size: 13px;
      margin-bottom: 0;
    }
    a{
      font-size: 13px;
      margin-bottom: 0;
      color: "grey";
    }
  }

  .text-grey{
    color: grey;
  }
  .policy-border{
    border-right: solid 2px;
    border-color: grey;
  }
  .text-underline{
    text-decoration: underline;
  }
.divider {
  height: 3px;
  background-color: #3e8993;
  width: 50%;
  color: #3e8993;
  margin: 0 auto; /* Center the divider */
  text-align: center;
  line-height: 2; 
  margin-bottom: 15px ;
}
.height-23{
    height: 23px;
}
.pt-35{
  padding-top: 35px;
}
.vertical-divider {
  width: 2px;
  height: 21px; /* Adjust the height of the vertical divider */
  background-color: #3e8993;
  display: inline-block;
  margin-top: 7px !important;
}
.border-right-responsive{
    border-right: solid 2px;
    border-color: grey;
    border-color: #5fb4be ;
    // padding-left: 10px;
    padding-right: 35px;
    height: 25px;
    top: 22px;
  }
}