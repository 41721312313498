// Styles for customer dashboard and customer -> myEvents dashboard
.customer-dashboard-con {
  color: white;
  // tab button  css
  button {
    font-weight: 500;
    font-size: 14px;
    color: $color-tabs-text;
  }

  // customer dashboard sidebar tabs btn style
  .MuiTabs-flexContainer {
    .customer-dashboard-tab-btn {
      min-width: 250px;
      clip-path: polygon(
        0 0,
        100% 0,
        100% 32.5%,
        97.5% 50%,
        100% 67.5%,
        100% 100%,
        0 100%
      );
    }
    .customer-dashboard-tab-btn[aria-selected="true"] {
      color: $color-white;
      background-color: $color-btn;
      border-right: unset;
      border: none;
    }
  }
  // make sidebar tabs btn right align
  .customer-dashboard-tab-btn {
    font-family: $sans-serif;
    align-items: flex-end;
    font-size: 16px;
  }
  // removes the tab indicator line from active tab
  .MuiTabs-indicator {
    display: none;
    // background-color: white;
    // width: 10px;
    // clip-path: polygon(0 50%, 100% 60%, 100% 40%);
  }
  // tabs content
  .tab-pannel {
    background-color: $color-text-paragraph-bg;
    // sort icon styles
    .event-sort-con {
      position: absolute;
      top: 0;
      right: 1px;
      cursor: pointer;
      padding: 5px;
    }
    .event-sort-icon {
      width: fit-content;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  }
  // removes x icon on invalid submission on all forms in dashboard, default styling of av
  .av-invalid {
    background-image: none;
  }
  // My Event Tab--------------------------------
  .my-events-con {
    background-color: $color-white;
    margin-bottom: 16px;
    border-top: none;
    word-break: break-all;
    transition-duration: 0.3s;
    &:hover {
      -webkit-box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
        rgba(0, 0, 0, 0.3) 0px 1px 3px -1px,
        rgba(50, 50, 93, 0.25) 0px -2px 5px -1px,
        rgba(0, 0, 0, 0.3) 0px -1px 3px -1px;
      -moz-box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
        rgba(0, 0, 0, 0.3) 0px 1px 3px -1px,
        rgba(50, 50, 93, 0.25) 0px -2px 5px -1px,
        rgba(0, 0, 0, 0.3) 0px -1px 3px -1px;
      box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
        rgba(0, 0, 0, 0.3) 0px 1px 3px -1px,
        rgba(50, 50, 93, 0.25) 0px -2px 5px -1px,
        rgba(0, 0, 0, 0.3) 0px -1px 3px -1px;
    }
    h3 {
      font-size: clamp(12px, 1vw, 18px);
      word-break: normal;
    }
    h5 {
      font-size: clamp(9px, 1vw, 14px);
      word-break: normal;
    }
    button {
      padding: 7px 15px;
      background: #3e8993;
      border: 1px solid #3e8993;
      color: #fff;
      font-weight: 500;
      border-radius: 5px;
      font-size: 16px;
      margin-right: 5px;
      transition: all 0.3s ease-in-out 0s;
      &:hover {
        opacity: 0.8;
      }
    }
    .dec-button {
      background-color: #9c9c9c;
    }
    .card-header {
      cursor: pointer;
      background-color: $color-white;
      margin: 0;
      .event-header-text {
        color: $color-heading;
      }
    }
    .event-card-footer {
      .main-con {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
      }
      figure {
        text-align: center;
      }
      h6 {
        text-align: center;
        color: $color-heading;
        font-weight: 600;
        font-family: $sans-serif;
        cursor: pointer;
        word-break: normal;
      }
      p {
        text-align: center;
        color: #252525;
        word-break: normal;
      }
    }
  }

  // My account tab styles-----------------------------------
  .my-account-tab {
    .my-account-heading {
      h5 {
        font-size: 20px;
      }
    }
    label {
      color: $color-text;
    }
    .shutterb-portfolio-btn {
      button {
        height: 50px;
        font-size: 20px;
        padding: 0 20px;
        line-height: 2.5;
        border: none;
        text-transform: capitalize;
      }
    }
    .account-form-devider {
      width: 1px;
      height: 100%;
      background-color: #ccc;
      box-shadow: #ccc 0px 2px 8px 0px;
    }
  }

  // Attended events, refer a friend & support tabs styles, be mindful before making the change-----------------------------------
  .attended-events-tab {
    h5 {
      color: $color-heading;
      font-size: 20px;
    }

    // Search result section
    .search-result {
      position: relative;
      border: 1px solid $color-btn;
      margin-bottom: 1rem;
      .shared-icon-con {
        position: absolute;
        color: blue;
        right: -5px;
        top: -12px;
        cursor: pointer;
      }
      .sb-list {
        margin-right: 10px;
        padding: 20px;
        box-sizing: border-box;
        margin-bottom: 1rem;
        background-color: white;
        border-radius: 5px;
        transition-duration: 0.3s;

        .profile-img-con {
          width: 50px;
          height: 50px;
          overflow: hidden;
          border-radius: 50%;
          border: 1px solid $color-btn;
          transition-duration: 0.5s;
          img {
            height: auto;
            width: auto;
            object-position: center;
            object-fit: cover;
            transition-duration: 0.5s;

            // remove padding to see circular view
            // padding: 5px;
          }
          &:hover {
            img {
              padding: 5px;
            }
          }
        }
      }
    }

    // Search bar and recent searches section
    form {
      min-width: 40%;
      input {
        padding: 14px 20px;
        font-family: $sans-serif;
        border: 1px solid $color-btn;
        margin-bottom: 1rem;
        border-radius: 5px;
      }
      .search-btn {
        font-size: 16px;
      }
    }
    .recent-search-list {
      li {
        color: $color-btn;
        font-size: 16px;
        font-family: $sans-serif;
      }
    }
    .view-event-btn {
      color: $color-white;
      font-size: 16px;
      font-family: $sans-serif;
    }
  }

  // support tab styles
  .supprt-tab {
    h5 {
      color: $color-heading;
      font-size: 20px;
    }
    form {
      min-width: 100%;
      label {
        color: $color-heading;
        font-weight: 500;
      }
      textarea {
        padding: 5px 10px;
        font-family: $sans-serif;
        border: 1px solid $color-btn;
        margin-bottom: 1rem;
        border-radius: 5px;
        min-height: 110px;
      }
    }
  }
}

// Styles for customer->myEvents dashboard
.customer-event-dashboard {
  .ed-sidebar-heading {
    color: $color-btn;
  }
  form {
    input {
      width: 22vw;
      border: none;
      padding: 10px 10px;
      margin-right: 10px;
      cursor: default;
      font-family: $sans-serif;
    }
    button[type="submit"] {
      :hover {
        background-color: $color-btn;
      }
    }
  }
  .events-dashboard-back-btn {
    background-color: white;
    border: none;
    color: $color-tabs-text;
    text-align: right;
    padding-right: 16px;
  }

  // Extend your sb and tip your sb tab content styles
  .extend-sb {
    h6 {
      color: $color-heading;
      font-weight: 600;
    }
    .profile-img-con {
      width: 110px;
      height: 110px;
      position: relative;
      input {
        position: absolute;
        right: 1px;
        top: 1px;
        width: 25px;
        height: 25px;
        border: none;
        border-radius: 3px;
        margin: 0px;
      }

      .profile-img {
        height: 100%;
        width: 100%;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .sb-price-items {
      label {
        width: 100%;
      }
      input {
        width: 100%;
        background-color: $color-input-readonly;
      }
    }
    .checkout-btn {
      button {
        box-shadow: none;
        transition-duration: 0.3s;
        &:hover {
          background-color: $color-btn;
          opacity: 0.7;
          color: $color-white;
        }
      }
    }
  }
  // Tip you sb tab content
  .tip-sb {
    .sb-price-group {
      input[type="radio"] {
        opacity: 0;
        position: fixed;
        width: 0;
      }
      label {
        display: inline-block;
        background-color: transparent;
        padding: 6px 20px;
        margin-right: 16px;
        border: 1px solid $color-btn;
        color: $color-heading;
        cursor: pointer;
        width: 4.25rem;
      }
      input[type="radio"]:checked + label {
        background-color: #dddddd;
        // border-color: #4c4;
      }
      .disabled-radio {
        opacity: 0.5;
        pointer-events: none;
      }
    }
  }
  // Access gallery tab content
  .access-gallery {
    .copy-btn {
      color: $color-white;
      box-shadow: unset;
      &:hover {
        color: $color-btn;
      }
    }
  }
}

.shared-gallery {
  font-family: $sans-serif;
  strong {
    color: $color-btn;
    cursor: pointer;
  }
}

.member-my-events-tabs {
  button[aria-selected="true"] {
    padding: 0px 25px;
    font-family: $sans-serif;
    color: black;
    font-weight: 600;
    background-color: white;
    border-top: 2px solid $color-btn;
  }
  button[aria-selected="false"] {
    padding: 0px 25px;
    font-family: $sans-serif;
    color: $color-tabs-disable;
    font-weight: 600;
    background-color: $color-bg-tabs-disable;
    // border-top: 1px solid $color-btn;
  }
}

.cancel-event-modal {
  width: 100%; /* Adjust the width as per your preference */
  height: 30%; /* Adjust the height as per your preference */
  max-width: 420px; /* Maximum width of the modal */
  max-height: 320px; /* Maximum height of the modal */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.reel-type {
  border: solid #35a8b3;
  box-shadow: 0px 0px 4px 2px #35a8b3;
}

.reel-type-div {
  flex-direction: column;
  flex-wrap: nowrap;
  border-radius: 0px;
  width: 100%;
  padding: 0px 0px 0px 0px;
  aspect-ratio: 1;
  margin-bottom: 7.5rem !important;
}


.aspect-ratio-container {
  position: relative;
  overflow: hidden;
}

.item-title{
  font-size: 24px;
  font-weight: bolder;
  position: absolute;
  margin-top: -51px;
  left: 30px;
}

.message-board {
  background-color: #fff;
  box-shadow: 1px 0 10px grey;
  height: 73vh;
  // border-radius: 10px;

  .messaged-board-height {
    height: 60vh;
    overflow-y: auto;
  }
  .receiver-messages {
    width: fit-content;
    max-width: 100%;
    padding: 8px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    word-wrap: break-word;
  }
  .sender-messages {
    width: fit-content;
    max-width: 100%;
    padding: 8px;
    border-radius: 10px;
    background-color: #3e8993;
    color: #fff;
    box-shadow: 0 0 0.5em rgba(76, 175, 80, 0.5);
    word-wrap: break-word;
  }
  .h-46 {
    height: 46px;
  }
  .sending-section {
    height: 10vh;
    margin: 0 auto;
    align-items: center;
  }

  .container-vh {
    height: 90vh;
  }
  .font-12 {
    font-size: 14px;
  }
  .margin-auto {
    margin: 0 auto;
  }
}

.reel-type-inner-text {
  font-size: small;
  font-weight: 600;
  background-color: rgba(0, 0, 0, 0.3);
  border: none;
  cursor: text;
  padding: 5px 9px;
}

.reel-type-img {
  cursor: pointer;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  box-shadow: 1px 0px 11px 0px;
}

.css-145v6pe-MuiButtonBase-root-MuiTabScrollButton-root.Mui-disabled{
  display: none;
}

.css-1q0ig8p{
  display: none !important;
}