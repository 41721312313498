* {
  font-family: $sans-serif !important;
}
// generic style classes
.custom-error {
  font-size: 12px;
  margin: 0;
  padding: 0;
  color: #dc3545;
}

// disable the div
.disable-div {
  opacity: 0.5;
  pointer-events: none;
}

// address search drop down
.address-suggestions-con {
  margin-top: -1rem;
  background-color: $color-white;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  font-size: 13px;
  font-weight: unset;
  .address-suggestions {
    font-family: "Arial", sans-serif !important;
    font-size: 13px;
    color: rgb(0, 0, 0);
    padding-top: 4px;
    padding-bottom: 4px;
    .map-icon {
      width: 16px;
      color: rgba(0, 0, 0, 0.5);
      margin-right: 2px;
      align-self: center;
    }
  }
  .google-trademark {
    text-align: right;
    color: rgb(0, 0, 0);
    font-weight: unset;

    .google-svg-con {
      height: 30px;
      display: inline-block;
      padding: 0px;
      svg {
        width: 100%;
      }
    }
  }
}

.not-found {
  min-height: 95vh;
}

.cursor-pointer {
  cursor: pointer !important;
}
