.faq .card {
    background: transparent;
    margin-bottom: 20px;
    border-collapse: collapse;
    border: none;
    box-shadow: 0px 0px 2px #ccc;
    .card-header {
        border: none !important;
        background: #f3f3f3;
        line-height: 1.8;
        a{
            font-size: 20px;
            color: #000;
            display: block;
            position: relative;
            width: 100%;
            font-weight: 600;
            background: transparent;
            @include breakpoint(desktop) {
                    font-size: 18px;
                  }
        }
    }
    .card-body {
        color: #5f5f5f;
        font-size: 18px;
        font-weight: 500;
        @include breakpoint(desktop) {
            font-size: 15px;
          }
    }
}
.faq [data-toggle="collapse"]:after {
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    content: "\f068";
    transform: rotate(0deg);
    transition: all linear 0.25s;
    top: 0px;
    position: absolute;
    font-size: 18px;
    color: #fff;
    background: #3e8993;
    right: -5px;
    width: 32px;
    height: 32px;
    text-align: center;
    border-radius: 30px;
    padding: 8px 2px;
    @include breakpoint(desktop) {
        font-size: 16px;
        width: 28px;
        height: 28px;
        padding: 7px 2px;
      }
}
.faq [data-toggle="collapse"].collapsed:after {
    content: "\f067";
    font-family: 'FontAwesome';
}
