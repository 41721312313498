.clr-white {
  color: white !important;
}

.teal-color {
  color: #3e8993 !important;
}

.left-btn {
  border: 1px solid !important;
}

.left-icon-image,.right-icon-image{
  height: 23px;
}

.right-btn {
  border: 1px solid !important;
}

.gallery-border {
  border: 1px solid #ccc !important;
}

.min-h-45 {
  height: auto !important;
  min-height: 45px !important;
}

.clr-grey {
  color: grey !important;
}

.min-width-100 {
  min-width: 100px !important;
}

.back-dull {
  background: #dee2e6 !important;
}

.custom-pl-1 {
  padding-left: 1rem !important;
}

.custom-pl {
  padding-left: 1.25rem !important;
}

.custom-pl-2 {
  padding-left: 2.5rem !important;
}

.custom-pl-3 {
  padding-left: 3rem !important;
}

.checkout-btn-style {
  padding: 0 20px !important;
  white-space: nowrap !important;
}

.clock-icon {
  background: white !important;
  border-left: none !important;
}

.clr-black {
  color: #000 !important;
}

.fs-16 {
  font-size: 16px !important;
}

.view-profile-color {
  background: #f8f8f8 !important;
  max-width: 100% !important;
}

.w-8 {
  width: 8rem !important;
}

.h-8 {
  height: 8rem !important;
}

.images-style {
  width: 15rem !important;
  height: 15rem !important;
  object-fit: cover !important;
  padding-top: 2rem !important;
  cursor: pointer !important;
}

.custom-mt{
    margin-top: 0.1rem !important;
}

.pointer-events-none{
    pointer-events: none !important;
}

.small-device-menu-items{
    background-color: #ededed;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 8px;
    margin-top: 5%;
    padding-bottom: 5%;
    height: max-content;
    border-top: 1px groove lightgrey;
}