.thank-you.book-your-event.event-book-flow{
    
    .thank-content.book-event-design.event-booking-cart-flow {
        max-width: 1300px;
        ul{
            display: flex;
            justify-content: center;
        }
    }
    .event-booking-cart-flow-left {
        display: flex;
        justify-content: center;
        align-items: center;
        figure {
            width: 200px;
            @include breakpoint(desktop) {
                width: 110px;
                  }
        }
        p {
            width: calc(100% - 0px);
            @include breakpoint(desktop) {
               padding-left: 35px;
                  }
        }
    }
    .event-booking-cart-flow-form {
        background: #eaeaea;
        padding: 20px 35px;
        margin-left: 20px;
        .heading{
            margin-bottom: 15px;
            @include breakpoint(desktop) {
              margin-bottom: 10px;
                }
        }
        form{
            .form-group{
                select.form-control {
                    border-radius: 0 !important;
                    height: 50px !important;
                    &:focus{
                        outline: none;
                        box-shadow: none;
                        border: 1px solid #dcdcdc;
                    }
                    @include breakpoint(desktop) {
                       height: 44px !important;
                       }
                }
                label{
                    @include breakpoint(desktop) {
                      font-size: 15px;
                        }
                }
            }
            button.btn {
                height: 48px;
                padding: 0 30px;
                font-size: 20px;
                line-height: 2.3;
                @include breakpoint(desktop) {
                    height: 42px;
                    padding: 0 25px;
                    font-size: 16px;
                    line-height: 2.5;
                    }
            }
        }
    }
}
