.login-form{
    background-color: $color-white;
    position: relative;
    padding: 0px 0px 16px 0px;
    max-width: 450px;
    height: fit-content;
    z-index: 1000;
    border-radius: 5px;
    // horizantally centers the form
    margin: auto;
    // margin-top: 200px;
    .form-header-group{
        padding: 16px;
        padding-bottom: 0px;
        h2{
            margin-bottom: 0;
            line-height: 1.5;
            font-weight: 500;
            font-size: 32px;
        }
    }
    .input-without-border-radius{
        border: none;
        background-color: $color-bg-input;
        padding: 0.0124px 10px;
        margin-top: 10px;
        height: 58px;
        &::placeholder{
            font-weight: 300;
        }
        // remove box shadow on selected that was the default styling of avfield
        &:focus{
            border: unset;
            outline: unset;
            box-shadow: unset;
        }
        
    }
    // avfield changing default styling ie removing x icon on invalid
    .av-invalid{
        background-image: none;
    }
    .form__form-group{
        padding: 16px;
        padding-top: 0px;
        padding-bottom: 0px;
        .account__btn{
            &:hover{
                box-shadow: none;
                background-color: $color-btn;
                opacity: 0.8;
                color: $color-white;
            }
            &:active{
                background-color: $color-btn;
            }
            // remove default btn styles of av on invalid submission
            &:focus{
                background-color: $color-btn;
                box-shadow: none;
                border: none;
            }
            
        }
        .submit-btn{
            font-size: 18px;
            width: 100%;
            height: 58px;
        }
        .oaut-fb-btn, .oaut-g-btn{
            display: flex;
            justify-content: flex-start;
            align-items: center;
            color: $color-white;
            cursor: pointer;
            vertical-align: middle;
            border-radius: 4px;
            background-color: $color-bg-fb-btn;
            padding: 0px 15px;
            border: none;
            font-size: 16px;
            height: 58px;
            transition-duration: 0.3s;
            img{
                width: 20px;
                height: 20px;
                margin-right: 10px;
            }
            .f-icon-con{
               margin-right: 10px;
               padding-left: 15px; 
               display: flex;
               justify-content: center;
               align-items: center;
               svg{
                width: 20px;
                height: 20px;
               }
            }
            
            
            &:hover{
                background-color: $color-bg-fb-btn;
                opacity: 0.9;
            }
            // login with facebook container library component
            span{
                width: 100%;
                height: 100%;

            }
        
        }
        .oauth-btn-container{
            width: 100%;
            position: relative;
        }
        
        .oaut-g-btn{
            background-color: $color-bg-g-btn;
            margin-top: 10px;
            &:hover{
                background-color: $color-bg-g-btn;
                opacity: 0.9;
            }
            img{
                margin-left: 15px; 
            }
            
            // login with facebook container library component
            .custom-google-btn{
                all: unset;
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                vertical-align: middle;
            }

 
            
        }

        // styles for helper msg in form
        .text-danger{
            margin: 0;
        }
        .invalid-feedback{
            font-size: 12px;
            margin: 0;
        }
        .signup-form-terms{
            width: 25px;
            height: 25px;
        }
        
        

    }
    a{
        transition: all 0.3s ease-in-out 0s;
        color: $color-btn;
        font-size: 14px;
        font-weight: 600;
        background-color: transparent;
        &:hover{
            color: $color-btn;
            opacity: 0.8;

        }
    }
    

    #cancel-icon{
        position: absolute;
        right: -12px;
        top: -16px;
        background: $color-btn;
        color: #fff;
        border-radius: 100%;
        width: 20px;
        height: 20px;
        z-index: 1;
        opacity: 1;
        line-height: 0;
        font-weight: 400;
        border: 3px solid $color-white;
        cursor: pointer;
        padding: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;

        span{
            font-size: 14px;
            font-weight: 700;
            text-align: center;
            font-family: $sans-serif;
            
        }
    }
    .facebook-button-class{
        background-color: transparent;
        border: none;
        color: white;
        width: 100%;
        height: 100%;
        text-align: left;
        font-family: $sans-serif;

    }
    // styles for divider lines in login form
    .login-form-divider{
        width: 42%;
        height: 10px;
        display: inline-block;
    }
}