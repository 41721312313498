.draft-event-submit {
  text-decoration: underline;
  font-weight: 700;
  cursor: pointer;
  transition-duration: 0.2s;
  color: $color-primary;
  &:hover {
    text-decoration: none;
  }
}
