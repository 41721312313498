.privacy-policy-content.terms-content{
    h6{
        text-transform: uppercase;
        line-height: 1.4;
        font-size: 22px;
        margin-bottom: 20px;
        @include breakpoint(desktop) {
            font-size: 20px;
            margin-bottom: 12px;
            }
    }
}