.thank-you {
    padding: 80px 0;
    @include breakpoint(desktop) {
        padding: 50px 0;
       }

.thank-content {
    width: 100%;
    max-width: 800px;
    margin: auto;
    text-align: center;
    margin-bottom: 80px;
    @include breakpoint(desktop) {
        margin-bottom: 50px;
       }
    h1{
        margin-bottom: 30px;
    }
    h6 {
        font-weight: 600;
        line-height: 1.5;
    }
}
.thanku-btns {
    display: flex;
    justify-content: center;
    a.btn.new-booking {
        margin: 0 20px 0 0px;
        background: transparent;
        border: 1px solid #000;
        color: #000;
        height: 52px;
        font-size: 20px;
        @include breakpoint(desktop) {
            height: 45px;
            font-size: 17px;
            line-height: 2.6;
           }
        &:hover{
            color: #3e8993;
            border: 1px solid #3e8993;
            box-shadow: none;
        }
    }
    a.btn.my-account{
        height: 52px;
        font-size: 20px;
        line-height: 2.6;
        border: none;
        @include breakpoint(desktop) {
            height: 45px;
            font-size: 17px;
            line-height: 2.6;
           }
        &:hover{
            box-shadow: none;
            border: none;
            background: #66d0da;
            color: #fff;
        }
    }
}
}