.thank-you.book-your-event{
    @include breakpoint(desktop) {
        padding: 50px 0;
       }
    .thank-content.book-event-design{
        width: 100%;
        max-width: 1000px;
        h1{
            margin-bottom: 80px;
            @include breakpoint(desktop) {
                margin-bottom: 70px;
               }
        }
        ul{
            display: flex;
            li{
                position: relative;
                font-size: 16px;
                width: 25%;
                font-weight: 600;
                @include breakpoint(desktop) {
                    font-size: 15px;
                   }
                &::before{
                    content: "";
                    position: absolute;
                    left: 120px;
                    top: -24px;
                    width: 12px;
                    height: 12px;
                    border-radius: 100%;
                    background: #ccc;
                }
                &::after{
                    content: "";
                    position: absolute;
                    top: -18px;
                    left: 120px;
                    width: 100%;
                    height: 2px;
                    background: #ccc;
                }
                &:last-child{
                    &::after{
                        display: none;
                    }
                }
                &.active{
                    &::before{
                        background: #3e8993;
                    }
                    &::after{
                        background: #3e8993;
                    }
                    &:nth-of-type(2){
                        &::before{
                            background: #3e8993;
                            border: 2px solid #fff;
                        }
                    }
                }
            }
        }
        
}
.book-event-logo {
    width: 100%;
    max-width: 400px;
    margin: auto;
    text-align: center;
    figure{
        margin-bottom: 30px;
    }
    h5{
        font-weight: 600;
        @include breakpoint(desktop) {
            margin-bottom: 0;
           }
    }
}
}
