$color-main-bg: #fff;
// $color-link-text: 
#topbar-header{
    background-color: $color-main-bg;
}
.main-header{
    background-color: $color-main-bg;
}
.navbar{
    padding: 12px 12px;
}

.nav-item a{
    all: unset;
    cursor: pointer;
    transition-duration: 0.3s;
    
}
.help-ul{
    position: relative;
}
.help-ul a{
    margin-right: 15px;
    &::after{
        content: "";
        background: #fff;
        width: 0px;
        height: 2px;
        display: block;
        transition: width 0.3s;
        position: absolute;
    }
    &:hover{
        &::after{
            width: 40px;
        }
    }
    a {
        color: #fff;
        &:hover{
            color: #bdbdbd;
            text-shadow: 0px 0px 1px #dcdcdc;
        }
    }

}

.contact_search{
    display: flex;
    align-items: center;
    margin-bottom: 0;
    li{
        a{
            color: white;
        }
    }
}

ul.contact_search li a.contact-us {
    padding: 12px 15px;
    background: #3e8993;
    border: 1px solid #3e8993;
    color: #fff;
    font-weight: 500;
    border-radius: 13px;
    font-size: 13px;
    font-weight: 600;
}

ul.contact_search li a.contact-us-disabled{
    pointer-events: none;
    color: #fff;
    background: #9c9c9c;
    border: 1px solid #9c9c9c;
    padding: 12px 15px;
    font-weight: 500;
    border-radius: 13px;
    font-size: 13px;
    font-weight: 600;
  }
header{
    padding: 10px 0 10px 0;
    background: #303030;
    position: relative;
    width: 100%;
    transition: all 0.4s ease;
    top: 0;
    left: 0;
    right: 0;
}

.white-icon {
    color: white;
  }