/*** Parameters ***/

// Colors
$color-primary: #37b2c9;
$color-secondary: #000;
$color-light: #e0e0e0;
$color-lightest: #efefef;
$color-brand: #ee5081;
$color-text: #5f5f5f;
$color-text-paragraph: #8f8f8f;
$color-text-paragraph-bg: #f8f8f8;
$color-white: #fff;
$color-btn: #3e8993;
$color-heading: #000000;
$color-bg-input: #f3f3f3;
$color-bg-g-btn: #dc4e41;
$color-bg-fb-btn: #1877f2;
$color-tabs-text: #606060;
$color-tabs-disable: #9c9c9c;
$color-bg-tabs-disable: #d8d8d8;
$color-event-body: #696969;
$color-input-readonly: #e9ecef;
$color-light-grey: #878787;

// Brand color
$color-facebook: #3b5998;
$color-feedly: #2bb24c;
$color-github: #333;
$color-google: #dc4e41;
$color-instagram: #3f729b;
$color-linkedin: #0077b5;
$color-medium: #00ab6b;
$color-messenger: #0084ff;
$color-rss: #f26522;
$color-spotify: #2ebd59;
$color-twitter: #55acee;

// Font Size
$base-font-size: 18;

// Font Family
$sans-serif: "Montserrat", sans-serif;

// common style variables
$my-sche-table-width: 730px;
