.home-banner {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 200px 0;
    background-attachment: fixed;
    @include breakpoint(desktop) {
        padding: 100px 0 50px 0;
    }
    .home-banner-content {
        display: flex;
        justify-content: space-between;
        .left-side {
            width: 740px;
            @include breakpoint(desktop) {
                width: 340px;
            }
        }
        .right-side {
            width: calc(100% - 740px);
            text-align: right;
            color: #fff;
            @include breakpoint(desktop) {
                width: calc(100% - 340px);
            }
            h1 {
                color: #fff;
                line-height: 1.4;
                margin-bottom: 20px;
                @include breakpoint(desktop) {
                    width: 100%;
                    max-width: 500px;
                    text-align: right;
                    margin-left: auto;
                }
            }
            h6 {
                line-height: 1.5;
                margin-bottom: 30px;
                @include breakpoint(desktop) {
                    margin-bottom: 20px;
                }
            }
        }
    }
}

.youtube-area {
    padding-bottom: 0;
    iframe {
        @include breakpoint(desktop) {
            height: 400px;
        }
    }
}

.home-functions {
    text-align: center;
    figure {
        overflow: hidden;
        width: 100%;
        height: 363px;
        img {
            transition: all .35s;
            transition-timing-function: linear;
            width: 100%;
            height: 100%;
            object-fit: cover;
            &:hover {
                transform: translate3d(-20px, 0, 0) scale(1.1, 1.1);
            }
        }
    }
    p {
        font-size: 16px;
    }
}

.how-we-work {
    color: #fff;
    text-align: center;
    padding: 60px 0 45px 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    @include breakpoint(desktop) {
        padding: 40px 25px;
    }
    p {
        color: #fff;
        margin-top: 15px;
        font-weight: 400;
        @include breakpoint(desktop) {
            margin-top: 8px;
        }
    }
    strong {
        font-weight: 600;
        font-size: 20px;
        @include breakpoint(desktop) {
            font-size: 18px;
        }
    }
    figure {
        margin-bottom: 30px;
        @include breakpoint(desktop) {
            margin-bottom: 20px;
        }
        img {
            @include breakpoint(desktop) {
                width: 60px;
            }
        }
    }
    .heading {
        margin-bottom: 80px;
        @include breakpoint(desktop) {
            margin-bottom: 45px;
        }
        h2 {
            font-weight: 600;
        }
    }
}

.meet-shutterbz {
    padding: 70px 0 20px 0;
    @include breakpoint(desktop) {
        padding: 50px 0 0px 0;
    }
    .heading {
        text-align: center;
        margin-bottom: 60px;
        @include breakpoint(desktop) {
            margin-bottom: 35px;
        }
        h2 {
            color: #000;
            font-weight: 600;
        }
        p {
            color: #353535;
            width: 100%;
            max-width: 980px;
            margin: auto;
        }
    }
    .meet-bz {
        display: flex;
        justify-content: center;
        align-items: center;
        .bz-single {
            text-align: center;
            width: 20%;
            margin: 0 15px 0 15px;
            @include breakpoint(desktop) {
                margin: 0 10px 0 10px;
            }
            ul {
                li {
                    color: #353535;
                    font-size: 16px;
                    @include breakpoint(desktop) {
                        font-size: 14px;
                    }
                }
            }
            strong {
                color: #000;
                font-size: 20px;
                @include breakpoint(desktop) {
                    font-size: 17px;
                }
            }
            figure {
                position: relative;
                overflow: hidden;
                width: 100%;
                height: 277px;
                @include breakpoint(desktop) {
                    height: 230px;
                }
                img {
                    transform: scale(1);
                    transition: .3s ease-in-out;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    &:hover {
                        transform: scale(1);
                        transform: scale(1.1);
                    }
                }
            }
        }
    }
}

.reviews {
    .testimonial-slider {
        .review-box {
            text-align: center;
            .heading {
                margin-bottom: 30px;
                @include breakpoint(desktop) {
                    margin-bottom: 20px;
                }
                h3 {
                    color: #000;
                    font-weight: 600;
                }
            }
            p {
                width: 100%;
                max-width: 1000px;
                margin: auto;
                position: relative;
                color: #000;
                z-index: 9999;
                @include breakpoint(desktop) {
                    max-width: 870px;
                }
                &::before {
                    content: "\f10d";
                    font-family: 'FontAwesome';
                    position: absolute;
                    top: -50px;
                    left: -40px;
                    font-size: 60px;
                    z-index: -9999;
                    color: #f6d9da;
                    @include breakpoint(desktop) {
                        top: -35px;
                        left: -25px;
                        font-size: 45px;
                    }
                }
            }
        }
        button.slick-prev.slick-arrow {
            padding: 0;
            font-size: 0;
            border: 0;
            &::before {
                content: "\f104";
                font-family: 'FontAwesome';
                position: absolute;
                left: 0;
                top: 40%;
                z-index: 1;
                font-size: 26px;
                color: #8a8a8a;
            }
        }
        button.slick-next.slick-arrow {
            padding: 0;
            font-size: 0;
            border: 0;
            &::before {
                content: "\f105";
                font-family: 'FontAwesome';
                position: absolute;
                right: 0;
                top: 40%;
                z-index: 1;
                font-size: 26px;
                color: #8a8a8a;
            }
        }
    }
}

.companies-worked-with {
    background: #353535;
    text-align: center;
    color: #fff;
    .heading {
        margin-bottom: 70px;
        @include breakpoint(desktop) {
            margin-bottom: 50px;
            ;
        }
    }
    .companies-slider {
        figure {
            display: flex;
            justify-content: space-around;
        }
    }
}

.gift-card {
    padding: 70px 0 30px 0;
    @include breakpoint(desktop) {
        padding: 60px 0 30px 0;
    }
    .heading {
        margin-bottom: 30px;
        @include breakpoint(desktop) {
            margin-bottom: 20px;
        }
        h3 {
            color: #000;
        }
    }
    p {
        color: #353535;
    }
    a.btn {
        font-size: 22px;
        line-height: 2.7;
        padding: 0 50px;
        height: 60px;
        @include breakpoint(desktop) {
            font-size: 18px;
            padding: 0 40px;
            height: 50px;
        }
    }
    .padd-left {
        @include breakpoint(desktop) {
            padding-left: 40px;
        }
    }
}

.instagram {
    .heading {
        text-align: center;
        color: #000;
        margin-bottom: 55px;
        @include breakpoint(desktop) {
            margin-bottom: 40px;
        }
        h3 {
            position: relative;
            &::before {
                content: "";
                position: absolute;
                top: 15px;
                left: 0;
                border: 1px solid #eee;
                width: 28%;
            }
            &::after {
                content: "";
                position: absolute;
                top: 15px;
                right: 0;
                border: 1px solid #eee;
                width: 28%;
            }
            .fa-instagram {
                color: #62b4be;
            }
        }
    }
    figure {
        &:hover {
            .overlay {
                opacity: 1;
                &::before {
                    opacity: 1;
                    z-index: 1;
                }
            }
        }
        img {}
    }
    .overlay {
        position: absolute;
        top: 33px;
        bottom: 0;
        left: 35px;
        right: 0;
        height: 85%;
        width: 85%;
        opacity: 0;
        transition: .5s ease;
        background: rgba(0, 0, 0, 0.6);
        @include breakpoint(desktop) {
            top: 25px;
            left: 38px;
            height: 85%;
            width: 80%;
        }
        &::before {
            content: "";
            background: rgba(0, 0, 0, 0.6);
            height: 100%;
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
            opacity: 0;
        }
        ul {
            color: #fff;
            font-size: 20px;
            position: absolute;
            top: 50%;
            left: 50%;
            -webkit-transform: translate(-50%, -50%);
            -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
            text-align: center;
            display: flex;
            li {
                i.fa.fa-instagram {
                    color: #fff;
                    font-size: 32px;
                    transition: all 0.3s;
                    &:hover {
                        color: #62b4be;
                    }
                }
            }
        }
        &:hover {
            opacity: 1;
        }
    }
    .row {
        margin-bottom: 20px;
    }
}

.companies-worked-with.with-partners {
    background: #528791;
}

.home-login-modal {
    .radio-button {
        margin: 0 20px 20px 0px;
        display: inline-block;
        &:nth-of-type(2) {
            margin: 0 0 0 60px;
        }
    }
    .radio-button__label-wrapper {
        border-radius: 50%;
        cursor: pointer;
        display: block;
        padding-left: 2.5rem;
        position: relative;
        top: 0.25rem;
        &:hover .radio-button__input~.radio-button__custom-indicator {
            border-color: #3e8993;
        }
    }
    .radio-button__label-title {
        margin-bottom: 0.25rem;
    }
    .radio-button__label-subtext {
        display: block;
        font-size: 16px;
        @include breakpoint(desktop) {
            font-size: 14px;
        }
    }
    .radio-button__custom-indicator {
        background-color: white;
        border: 1px solid #999;
        border-radius: 50%;
        height: 23px;
        left: 0;
        position: absolute;
        top: 0;
        width: 22px;
        @include breakpoint(desktop) {
            width: 18px;
            height: 18px;
        }
        &::after {
            background-color: #3e8993;
            border-radius: 50%;
            content: "";
            display: none;
            height: 14px;
            left: 3px;
            position: absolute;
            top: 3px;
            width: 14px;
            @include breakpoint(desktop) {
                height: 12px;
                left: 2px;
                top: 2px;
                width: 12px;
            }
        }
    }
    .radio-button__input {
        opacity: 0;
        position: absolute;
        z-index: -1;
        &:focus~.radio-button__custom-indicator {
            border-color: #3e8993
        }
        &:checked~.radio-button__custom-indicator {
            &::after {
                display: block;
            }
        }
        &:disabled~.radio-button__custom-indicator {
            opacity: 0.6;
            pointer-events: none;
        }
    }
    .modal-content {
        padding: 0 30px;
        @include breakpoint(desktop) {
            padding: 0 20px;
        }
        .modal-header {
            border-bottom: none !important;
            position: relative;
            padding-bottom: 0;
            button.close {
                position: absolute;
                right: -20px;
                top: 2px;
                background: #3e8993;
                color: #fff;
                border-radius: 100%;
                width: 20px;
                height: 20px;
                text-align: center;
                z-index: 1;
                opacity: 1;
                line-height: 0;
                font-weight: 400;
                font-size: 20px;
                border: 3px solid #fff;
                span {
                    font-size: 30px;
                    position: relative;
                    left: -8px;
                    top: 0px;
                }
            }
        }
        .login-rd-btns {
            width: 100%;
            margin: auto;
            display: flex;
            justify-content: flex-end;
        }
        form {
            .form-group {
                input.form-control {
                    border-radius: 0 !important;
                    border: none !important;
                    background: #f3f3f3;
                    @include breakpoint(desktop) {
                        height: 44px;
                        font-size: 15px;
                    }
                }
            }
            .forgot-password {
                p {
                    text-align: right;
                    a {
                        color: #3e8993;
                        font-weight: 600;
                        &:hover {
                            color: #70cdd6;
                        }
                    }
                }
            }
            .login-btn {
                margin-bottom: 30px;
                @include breakpoint(desktop) {
                    margin-bottom: 20px;
                }
                button.btn {
                    display: flex;
                    width: 100%;
                    text-align: center;
                    justify-content: center;
                    height: 56px;
                    line-height: 2.5;
                    font-size: 22px;
                    @include breakpoint(desktop) {
                        height: 46px;
                        line-height: 2.7;
                        font-size: 16px;
                    }
                    &:hover {
                        color: #fff;
                        box-shadow: none;
                        background: #70cdd6;
                        border: 1px solid #70cdd6;
                    }
                }
            }
            .signup-now {
                p {
                    text-align: center;
                    a {
                        color: #3e8993;
                        font-weight: 600;
                        &:hover {
                            color: #70cdd6;
                        }
                    }
                }
            }
            .or {
                p {
                    text-align: center;
                    position: relative;
                    font-weight: 600;
                    &::before {
                        content: "";
                        position: absolute;
                        top: 15px;
                        left: 0;
                        background: #d6d6d6;
                        width: 44%;
                        height: 1px;
                    }
                    &::after {
                        content: "";
                        position: absolute;
                        top: 15px;
                        right: 0;
                        background: #d6d6d6;
                        width: 44%;
                        height: 1px;
                    }
                }
            }
            .fb {
                margin-bottom: 15px;
            }
            .google {
                margin-bottom: 25px;
            }
            .login-click-here {
                p {
                    text-align: center;
                    a {
                        color: #3e8993;
                        font-weight: 600;
                        &:hover {
                            color: #70cdd6;
                        }
                    }
                }
            }
        }
        .modal-body-content-p {
            p {
                text-align: left;
                margin-bottom: 20px;
                @include breakpoint(desktop) {
                    margin-bottom: 10px;
                }
            }
        }
    }
}

.modal-dialog {
    @include breakpoint(desktop) {
        width: 100%;
        max-width: 450px;
    }
}