.blog{
    padding-bottom: 10px;
    .single-blog {
        box-shadow: 0px 0px 10px #dadada;
        width: 414px;
        height: 100%;
        position: relative;
        figure {
            width: 100%;
            height: 340px;
            @include breakpoint(desktop) {
                height: 250px;
                }
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        @include breakpoint(desktop) {
            width: 340px;
            }
        // &::after{
        //     content: "\f1e0";
        //     font-family: 'FontAwesome';
        //     position: absolute;
        //     right: 0;
        //     top: 0;
        //     font-size: 22px;
        //     color: #474747;
        //     background-color: rgba(255, 255, 255, 0.5);
        //     padding: 0px 8px;
        //     cursor: pointer;

        // }
        .blog-content {
            padding: 0 15px 0 25px;
            @include breakpoint(desktop) {
                padding: 0 15px 0 18px;
                }
            h6{
                font-size: 20px;
                font-weight: 600;
                margin-bottom: 10px;
                @include breakpoint(desktop) {
                    margin-bottom: 7px;
                    }
                a{
                    color: #000;
                    text-transform: uppercase;
                    @include breakpoint(desktop) {
                        font-size: 17px;
                        }
                }
            }
            .blog-tag{
                margin-bottom: 10px;
                @include breakpoint(desktop) {
                    margin-bottom: 7px;
                    }
            a {
                background: #ebebeb;
                font-size: 15px;
                color: #666666;
                padding: 2px 10px;
                font-weight: 600;
                transition: all 0.3s;
                @include breakpoint(desktop) {
                    font-size: 13px;
                    }
                &:hover{
                    box-shadow: 6px 0px 2px #ebebeb;
                }
            }
        }
        p{
            color: #5f5f5f;
            margin-bottom: 20px;
            @include breakpoint(desktop) {
                margin-bottom: 10px;
                }
        }
        .read-more-blog {
            margin-bottom: 20px;
            a{
                color: #74b2bc;
                font-weight: 500;
                @include breakpoint(desktop) {
                    font-size: 15px;
                    }
                &:hover{
                   text-decoration: underline;
                }
            }
        }
        }
        .share-icon {
            position: absolute;
            right: 0;
            top: 0;
            font-size: 22px;
            background-color: rgba(255,255,255,0.5);
            padding: 0 8.5px;
            a{
                color: #474747;
            }
        }
        .search-form {
            position: absolute;
            top: 35px;
            right: 0;
            transform: translateY(20px);
            opacity: 0;
            pointer-events: none;
            transition: all ease-in-out 0.3s;
        
            &.open
            {
                transform: translateY(0);
                opacity: 1;
                pointer-events: visible;
            
            }
        
            form {
                display: flex;
                .form-group{
                    input.form-control {
                        height: 45px !important;
                        border-radius: 0 !important;
                    }
                }
                button.btn {
                    height: 45px;
                    font-size: 16px;
                    &:hover{
                        background: #1e77c1 !important;
                        color: #fff;
                        transition: none;
                      &::after{
                          display: none;
                      }
                      &::before{
                        display: none;
                    }
                    }
                    &:focus{
                        outline: none;
                        box-shadow: none;
                        border: 2px solid #1e77c1;
                    }
                }
                a{
                    i.fa.fa-times {
                        color: #fff;
                        position: relative;
                        left: 10px;
                        top: 8px;
                        font-size: 18px;
                        // box-shadow: 6px 0px 12px 2px #484848;
                    }
                }
            }
        }
        a.search-icon.btn2 {
            background: #dadada;
            width: 42px;
            height: 36px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
        
            // &::before{ 
            //     content: "\f002";
            //     font: normal normal normal 14px/1 FontAwesome;
            //     font-size: 16px;
            //     color: #000;
            // }
        
            &:after
            {
                display: none;
            }
            &.close 
            // {
            //     &::before{ 
            //         content: "\f00d";
            //         font: normal normal normal 14px/1 FontAwesome;
            //         font-size: 16px;
            //         color: #000;
            //     }
            // }
        
           
        
            &:hover{
                background: #eee;
            }
        }
        li.nav-item.last-slot{
            // &:hover{
            //     &::after{
            //         display: none;
            //     }
            // }
        }
        .search-form{
            ul{
                li{
                    a {
                        color: #74b2bc;
                        font-size: 22px;
                        background-color: azure;
                        display: flex;
                        justify-content: center;
                        padding: 10px 8px;
                        margin: 0 0 0px 0;
                    }
                }
            }
        }
        .close {
            float: none;
            font-size: 22px;
            font-weight: 600;
            line-height: 0;
            color: #474747;
            text-shadow: none;
            opacity: 1;
           
        }
    }
    .row{
        margin-bottom: 50px;
        &:nth-of-type(3){
            margin-bottom: 70px;
        }
        &:last-child{
            margin-bottom: 0;
        }
    }
    .blog-pagination {
        justify-content: center;
        display: flex;
        ul{
            background-color: rgba(239, 239, 239, 0.5);
            box-shadow: 10px 2px 25px 0px #ccc;
            li.page-item {
                margin: 10px 10px 10px 10px;
                .page-link {
                    padding: .2rem .6rem;
                    color: #000;
                    background-color: transparent;
                    border: none;
                    font-size: 14px;
                    font-weight: 600;
                    &:hover{
                        background: #528791;
                        color: #fff;
                        border-radius: 100%;
                    }
                    &:focus{
                        outline: none;
                        box-shadow: none;
                        border: none;
                    }
                    span {
                        margin: 0px 10px 0px 10px;
                    }
                }
                &.active{
                    .page-link{
                        background: #528791;
                        color: #fff;
                        border-radius: 100%;
                    }
                }
                &:first-child{
                    margin-right: 50px;
                    position: relative;
                    .page-link{
                        &:hover{
                            border-radius: none !important;
                        }
                    }
                  
                    // &::before{
                    //     content: "\f177";
                    //     font-family: 'FontAwesome';
                    //     position: absolute;
                    //     top: -4px;
                    //     left: -25px;
                    //     font-size: 20px;
                    // }
                  
                }
                &:last-child{
                    margin-left: 50px;
                    position: relative;
                    // &::after{
                    //     content: "\f178";
                    //     font-family: 'FontAwesome';
                    //     position: absolute;
                    //     top: -4px;
                    //     right: -25px;
                    //     font-size: 20px;
                    // }
                }
            }
        }
    }
}