.gallery-btn {
  padding: 7px 24px;
  background: #3e8993;
  border: 1px solid #3e8993;
  color: #fff !important;
  font-weight: 500;
  border-radius: 5px;
  font-size: 16px;
  margin-right: 5px;
  transition: all 0.3s ease-in-out 0s;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
}
.gallery-btn-simple{
  all: unset;
  margin-right: 10px;
  padding: 6px 15px;
  color: #4b4b4b;
  background: linear-gradient(#c9c9c9,#ccc);
  border-radius: 2px;
  transition-duration: 0.3s;
}

.gallery-btn:hover, .gallery-btn-simple:hover {
  opacity: 0.8;
}
.progress-bar {
  background-color: #3e8993 !important;
}
.gallery-btn:disabled {
  opacity: 0.5;
  pointer-events: none;
  background: #9c9c9c;
  border: 1px solid #9c9c9c;
}

.selected-gallery-image {
  border: 4px solid #3e8993 !important;
}

.file-preview {
  display: flex;
  flex-wrap: wrap;
  /*gap: 10px;*/
  background: white;
  padding: 0.5rem;
  padding-bottom: 0;
}

.file-item {
  position: relative;
  /*display: flex;*/
  justify-content: center;
  align-items: center;
  border: 1px solid white;
  /*padding-bottom: 100%; /* Height equal to width */
}

/* Remove height and object-fit from video and img */
.file-item video,
.file-item img {
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  text-align: center;
  width: 100%;
}


.aspect-ratio-container {
  position: relative;
  overflow: hidden;
  padding-bottom: 100%; /* Height equal to width */
}

.file-content {
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.reel-file-Content{
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.checkbox-container {
  position: absolute;
  top: 2px;
  right: 20px;
  z-index: 1;
  cursor: pointer;
}

.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.checkmark {
  position: relative;
  display: inline-block;
  width: 15px;
  height: 15px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 3px;
  left: 100%;
}

.checkbox-container input:checked ~ .checkmark {
  background-color: #3e8993;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.checkbox-container input:checked ~ .checkmark:after {
  display: block;
}

.checkbox-container .checkmark:after {
  left: 4px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.image-modal {
  width: 80%; /* Adjust the width as per your preference */
  height: 80%; /* Adjust the height as per your preference */
  max-width: 800px; /* Maximum width of the modal */
  max-height: 600px; /* Maximum height of the modal */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.reason-modal {
  width: 80%; 
  height: 80%; 
  max-width: 800px; 
  max-height: 600px;
  position: absolute;
  top: 20rem;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid black;
}

.modal-overlay {
  background-color: rgba(0, 0, 0, 0.8); /* Overlay background color */
}

.modal-content {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-image {
  max-width: 100%;
  max-height: 100%;
}

.modal-close {
  opacity: 0.7;
  position: absolute;
  top: 17px;
    right: -1px;
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
  width: 35px;
  height: 35px;

  transform: translateY(-50%);
  color: #3e8993;
  font-size: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s ease;
  background-color: #3e8993;
  border: 1px solid;
  padding-right: 10px;
  padding-left: 10px;
}

.modal-close-img {
  height:21px !important;
  max-width: 200% !important;
}

.modal-previous,
.modal-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  color: #fff;
  font-size: 18px;
  cursor: pointer;
}

.modal-previous {
  left: 10px;
  color: black;
}

.modal-next {
  border: 1px solid;
  right: -20px;
}

.bold-text {
  font-weight: bold;
  color: #000;
}

.bg-none{
  background: none;
}

.px-lg-7 {
  padding-left: 7rem !important;
  padding-right: 7rem !important;
}

.lock-container {
  position: absolute;
  top: 2px;
  left: 5px;
  z-index: 1;
  color: #3e8993;
}

.image-download-icon {
  opacity: 0.7;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.5);
  border: none;
  align-items: center;
  text-align: center;
  padding: 8px;
  cursor: pointer;
  display: flex;
}

.video-download-icon{
  opacity: 0.7;
    position: absolute;
      top: 0px;
      left: 0px;
      z-index: 1000;
      background-color: rgba(0, 0, 0, 0.5);
      border: none;
      align-items: center;
      text-align: center;
      padding: 8px;
      cursor: pointer;
      display: flex;
}

.play-button-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2; 
  color: white;
}

.mh-3{
  min-height: 3rem
}






.rating-input {
  margin-bottom: 20px;
}

.stars {
  display: flex;
  align-items: center;
}

.star {
  font-size: 24px;
  color: #ccc;
  cursor: pointer;
}

.star.active {
  color: #3e8993;
}

.feedback-input {
  margin-bottom: 20px;
}

.feedback-text-area{
  border-radius: 5px;
  border: 1px solid #3e8993;
}

.custom-datepicker-popper .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
  background-color: #17a2b8;
  color: white;
  font-weight: bold;
}

.custom-datepicker-popper .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected:hover {
  background-color: #17a2b8;
  color: white;
  font-weight: bold;
}
.is-invalid-time{
border-color: #dc3545 !important;
}

.is-time-field-empty{
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
  }

.mw-175{
  min-width: 175px;
}

.mw-160{
  min-width: 160px !important;
}

.css-19kzrtu {
  padding-top: 24px !important;
  padding-bottom: 24px !important;
  padding-left: 4px !important;
  padding-right: 4px !important;
}

.account-btn-padding{
  padding-bottom: 10px !important;
    padding-top: 5px !important;
}

.obj-fit-cover{
  object-fit: cover !important;
}

.cross-icon{
  background: #656565;
    border-radius: 100px;
    width: 17px;
    height: 17px;
    opacity: 1;
    color: #fff !important;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    padding: 5px;
    top: -5px;
    right: 0px;
    z-index: 2;
}

.cross-icon:hover,
.cross-icon:active {
  opacity: 0.8;
  color: #fff !important; 
  cursor: pointer;
}

.color-teal{
  color: #3e8993 !important;
  transition: color 0.3s;
}

.color-teal:hover {
  opacity: 0.8;
}

.rmdp-arrow{
  height: 10px !important;
  width: 10px !important;
}

.access-gallery-fonts{
  font-family: "Montserrat", sans-serif !important;
}