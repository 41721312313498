.home-banner.blog-detail-bnr {
    padding: 80px 0 50px 0;
    @include breakpoint(desktop) {
        padding:50px 0 30px 0; 
        }
        h1{
            @include breakpoint(desktop) {
                max-width: 530px !important;
                }
        }
        .left-side{
            width: 280px;
        }
}
.blog-detail-top-banner {
    width: 100%;
    max-width: 1195px;
    margin: auto;
    box-shadow: 0px 0px 6px #ccc;
    border-radius: 5px;
    position: relative;
    @include breakpoint(desktop) {
        max-width: 950px;
        }
    .blog-detail-top-banner-content {
        padding: 10px 30px;
        h6{
            a{
            text-transform: uppercase;
            color: #000;
            font-weight: 600;
            font-size: 22px;
            background: transparent;
            padding: 0;
            @include breakpoint(desktop) {
                font-size: 18px;
                }
            }
        }
        a{
            background: #ebebeb;
            font-size: 15px;
            color: #666666;
            padding: 2px 10px;
            font-weight: 600;
            transition: all 0.3s;
            &:hover{
                box-shadow: 6px 0px 2px #ebebeb;
            }
        }
        p{
            &:first-of-type{
                    color: #606060;
                    font-weight: 600;
                    margin-bottom: 10px;
            }
            span {
                margin: 0 0 0 20px;
                position: relative;
                padding-left: 20px;
                &::before{
                    content: "";
                    position: absolute;
                    top: 6px;
                    left: 0;
                    background: #606060;
                    width: 5px;
                    height: 5px;
                    border-radius: 100%;
                }
            }
            &:nth-of-type(2){
                margin-top: 15px;
            }
        }
    }
}
.blog.blog-detail-blog{
    margin-bottom: 50px;
  
    .heading{
        h3{
            color: #000;
            text-align: center;
        }
    }
      
}

.blog-detail-top-banner{
    .share-icon {
        position: absolute;
        right: 0;
        top: 0;
        font-size: 22px;
        background-color: rgba(255,255,255,0.5);
        padding: 0 8.5px;
        a{
            color: #474747;
        }
    }
    .search-form {
        position: absolute;
        top: 35px;
        right: 0;
        transform: translateY(20px);
        opacity: 0;
        pointer-events: none;
        transition: all ease-in-out 0.3s;
    
        &.open
        {
            transform: translateY(0);
            opacity: 1;
            pointer-events: visible;
        
        }
    }
    .search-form{
        ul{
            li{
                a {
                    color: #74b2bc;
                    font-size: 22px;
                    background-color: azure;
                    display: flex;
                    justify-content: center;
                    padding: 10px 8px;
                    margin: 0 0 0px 0;
                }
            }
        }
    }
    .close {
        float: none;
        font-size: 22px;
        font-weight: 600;
        line-height: 0;
        color: #474747;
        text-shadow: none;
        opacity: 1;
       
    }
}
.blog-detail{
    @include breakpoint(desktop) {
        padding-bottom: 10px;
          }
}