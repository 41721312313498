.thank-you.book-your-event.event-book-flow.event-product-page{
    .product-page-cart-table{
        width: 100%;
        max-width: 1200px;
        margin: auto;
        @include breakpoint(desktop) {
            max-width: 950px;
          }
        table{
            width: 100%;
            border: 1px solid #eaeaea;
            tr{
                th {
                    text-align: inherit;
                    background: #eaeaea;
                    padding: 15px 40px;
                    @include breakpoint(desktop) {
                            padding: 10px 40px;
                            font-size: 16px;
                          }
                    
                }
                td {
                    padding: 20px 40px 10px 40px;
                    vertical-align: top;
                    @include breakpoint(desktop) {
                       font-size: 15px;
                       padding: 20px 40px 0px 40px;
                      }
                    figure {
                        @include breakpoint(desktop) {
                            width: 80px;
                          }
                        
                    }
                }
                &:last-child{
                    td{
                        text-align: right;
                        padding-bottom: 60px;
                        vertical-align: top;
                        a.btn.new-booking {
                            background: transparent;
                            border: 1px solid #000;
                            color: #000;
                            height: 50px;
                            font-size: 20px;
                            line-height: 2.4;
                            padding: 0 30px;
                            @include breakpoint(desktop) {
                                height: 42px;
                                font-size: 16px;
                                line-height: 2.6;
                              }
                            &:hover{
                                color: #3e8993;
                                border: 1px solid #3e8993;
                                box-shadow: none;
                            }
                        }
                        a.btn.my-account {
                            height: 50px;
                            font-size: 20px;
                            line-height: 2.6;
                            border: none;
                            padding: 0 30px;
                            width: 185px;
                            @include breakpoint(desktop) {
                                height: 42px;
                                font-size: 16px;
                                line-height: 2.7;
                              }
                            &:hover{
                                box-shadow: none;
                                border: none;
                                background: #66d0da;
                                color: #fff;
                            }
                        }
                    }
                }
            }
        }
    }
}