@media screen and (min-width: 1500px) {
    .container {
        max-width: 1470px;
    }
}

ul {
    padding: 0;
    li {
        list-style: none;
    }
}

section {
    padding: 50px 0;
    @include breakpoint(desktop) {
        padding: 40px 0;
    }
    @include breakpoint(normal) {
        padding: 25px 0;
    }
}

@keyframes shake {
    10%,
    30% {
        transform: translate3d(-1px, 0, 0);
    }
    20%,
    40% {
        transform: translate3d(2px, 0, 0);
    }
    30%,
    50%,
    50% {
        transform: translate3d(-4px, 0, 0);
    }
    40%,
    50% {
        transform: translate3d(4px, 0, 0);
    }
}

@-webkit-keyframes shine {
    from {
        -webkit-mask-position: 150%;
    }
    to {
        -webkit-mask-position: -50%;
    }
}

.nav-tabs {
    border-bottom: none;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
    color: #495057;
    background-color: transparent;
    border-color: transparent;
}

.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
    border-color: transparent;
}

.nav-tabs .nav-link {
    border: inherit;
    border-top-left-radius: 50px !important;
    border-top-right-radius: 50px;
}

.nav-tabs .nav-link.link-img {
    &:hover {
        &::after {
            width: 190px;
            @include breakpoint(desktop) {
                width: 150px;
            }
        }
    }
    &::after {
        content: "";
        position: absolute;
        background: #2d93fd;
        width: 0px;
        height: 5px;
        display: block;
        transition: width 0.3s;
        bottom: -20px;
    }
}

.tab-content>.active {
    display: block;
    width: 900px;
    @include breakpoint(desktop) {
        width: 700px;
    }
}

// Vars
$slick-dot-width: 20px;
$slick-dot-height: 20px;
$slick-dot-color: white;
$slick-dot-color-active: $slick-dot-color;
$slick-opacity-default: 0.75;
$slick-opacity-on-hover: 1;
$slick-opacity-not-active: 0.25;
$playball: "Playball",
cursive;
$poppins: "Poppins",
sans-serif;
// Design Slider
.slider,
.slide {
    @media (min-width: 992px) {
        height: 80vh;
    }
}

.slide {
    position: relative;
    transition: 1s;
    .slide__img {
        @media (min-width: 992px) {
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
        }
        width: 100%;
        height: auto;
        overflow: hidden;
        img {
            max-width: 100%;
            height: auto;
            opacity: 1 !important;
            animation-duration: 3s;
            transition: all 1s ease;
        }
    }
    .slide__content {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        &.slide__content__left {
            left: 15%;
            transform: translate(-15%, -50%);
        }
        &.slide__content__right {
            right: 15%;
            left: auto;
            transform: translate(5%, -50%);
        }
        &--headings {
            color: #fff;
            h2 {
                font-size: 4.5rem;
                margin: 10px 0;
            }
            .animated {
                transition: all 0.5s ease;
            }
            .top-title {
                font-family: $playball;
                font-size: 2.5rem;
            }
            .title {
                font-size: 3.5rem;
            }
            .button-custom {
                text-decoration: none;
                color: #333;
                padding: 1.2rem 2.5rem;
                font-size: 1.5rem;
            }
        }
    }
}

// Change animation presets
.slider [data-animation-in] {
    opacity: 0;
    animation-duration: 1.5s;
    transition: opacity 0.5s ease 0.3s;
    transition: 1s;
}

// Dots
.slick-dotted .slick-slider {
    margin-bottom: 30px;
}

.slick-dots {
    position: absolute;
    bottom: 120px;
    list-style: none;
    display: block;
    padding: 0;
    margin: 0;
    width: calc(100% - 220px);
    left: 220px;
    @include breakpoint(desktop) {
        width: calc(100% - 123px);
        left: 123px;
        bottom: 75px;
    }
    li {
        position: relative;
        display: inline-block;
        margin: 0 9px;
        padding: 0;
        background: #fff;
        cursor: pointer;
        button {
            border: 0;
            display: block;
            outline: none;
            line-height: 0px;
            font-size: 0px;
            color: transparent;
            padding: 5px;
            cursor: pointer;
            transition: all 0.3s ease;
            &:hover,
            &:focus {
                outline: none;
            }
        }
    }
}

.simple-dots {
    .slick-dots {
        li {
            width: $slick-dot-width;
            height: $slick-dot-height;
            button {
                border-radius: 50%;
                background-color: $slick-dot-color;
                opacity: $slick-opacity-not-active;
                width: $slick-dot-width;
                height: $slick-dot-height;
                &:hover,
                &:focus {
                    opacity: $slick-opacity-on-hover;
                }
            }
            &.slick-active button {
                color: $slick-dot-color-active;
                opacity: $slick-opacity-default;
            }
        }
    }
}

.stick-dots {
    .slick-dots {
        li {
            button {
                position: relative;
                background: #fff;
                opacity: $slick-opacity-not-active;
                width: 13px;
                height: 13px;
                padding: 0;
                color: #fff;
                @include breakpoint(desktop) {
                    width: 11px;
                    height: 11px;
                }
                &:hover,
                &:focus {
                    opacity: $slick-opacity-on-hover;
                }
            }
            &.slick-active button {
                color: $slick-dot-color-active;
                opacity: $slick-opacity-default;
                background: #1e77c1;
                &:hover,
                &:focus {
                    opacity: $slick-opacity-on-hover;
                }
            }
        }
    }
}


/* /////////// IMAGE ZOOM /////////// */

@keyframes zoomInImage {
    from {
        transform: scale3d(1, 1, 1);
        transition: 1s;
    }
    to {
        transform: scale3d(1.1, 1.1, 1.1);
        transition: 1s;
    }
}

.zoomInImage {
    animation-name: zoomInImage;
}

@keyframes zoomOutImage {
    from {
        transform: scale3d(1.1, 1.1, 1.1);
        transition: 1s;
    }
    to {
        transform: scale3d(1, 1, 1);
        transition: 1s;
    }
}

.zoomOutImage {
    animation-name: zoomOutImage;
    transition: 1s;
}

.slick-nav {
    --active: #fff;
    --border: rgba(255, 255, 255, 0.12);
    width: 60px;
    height: 60px;
    position: absolute;
    cursor: pointer;
    top: calc(50% - 44px);
    &.prev-arrow {
        left: 8%;
        transform: scaleX(-1);
        z-index: 999;
    }
    &.next-arrow {
        left: auto;
        right: 8%;
    }
    i {
        display: block;
        position: absolute;
        margin: -10px 0 0 -10px;
        width: 22px;
        height: 22px;
        left: 50%;
        top: 50%;
        z-index: 1;
        @include breakpoint(desktop) {
            width: 20px;
            height: 20px;
        }
        &:before,
        &:after {
            content: "";
            width: 10px;
            height: 2px;
            border-radius: 1px;
            position: absolute;
            left: 50%;
            top: 50%;
            background: var(--active);
            margin: -1px 0 0 -5px;
            display: block;
            transform-origin: 9px 50%;
        }
        &:before {
            transform: rotate(-40deg);
        }
        &:after {
            transform: rotate(40deg);
        }
    }
    &:before,
    &:after {
        content: "";
        display: block;
        position: absolute;
        left: 1px;
        right: 1px;
        top: 1px;
        bottom: 1px;
        border-radius: 50%;
        border: 2px solid var(--border);
        background: linear-gradient(45deg, #f18221, #f4a51d);
    }
    svg {
        width: 60px;
        height: 60px;
        display: block;
        position: relative;
        z-index: 1;
        color: var(--active);
        stroke-width: 2px;
        stroke-dashoffset: 126;
        stroke-dasharray: 126 126 0;
        transform: rotate(0deg);
        transition: all 0.3s ease-in-out;
        @include breakpoint(desktop) {
            width: 50px;
            height: 50px;
        }
        &:hover {
            box-shadow: 0px 0px 2px 1px #f4a51d;
            border-radius: 100%;
            border: none;
            outline: none;
        }
    }
    &.animate {
        svg {
            animation: stroke 1s ease forwards 0.3s;
        }
        i {
            animation: arrow 1.6s ease forwards;
            &:before {
                animation: arrowUp 1.6s ease forwards;
            }
            &:after {
                animation: arrowDown 1.6s ease forwards;
            }
        }
    }
}

@keyframes stroke {
    52% {
        transform: rotate(-180deg);
        stroke-dashoffset: 0;
    }
    52.1% {
        transform: rotate(-360deg);
        stroke-dashoffset: 0;
    }
    100% {
        transform: rotate(-180deg);
        stroke-dashoffset: 126;
    }
}

@keyframes arrow {
    0%,
    100% {
        transform: translateX(0);
        opacity: 1;
    }
    23% {
        transform: translateX(17px);
        opacity: 1;
    }
    24%,
    80% {
        transform: translateX(-22px);
        opacity: 0;
    }
    81% {
        opacity: 1;
        transform: translateX(-22px);
    }
}

@keyframes arrowUp {
    0%,
    100% {
        transform: rotate(-40deg) scaleX(1);
    }
    20%,
    80% {
        transform: rotate(0deg) scaleX(0.1);
    }
}

@keyframes arrowDown {
    0%,
    100% {
        transform: rotate(40deg) scaleX(1);
    }
    20%,
    80% {
        transform: rotate(0deg) scaleX(0.1);
    }
}

.custom-radio-wrap {
    margin-bottom: 10px;
}

.custom-radio-wrap form .form-group {
    margin-bottom: 10px;
    text-align: left;
}

.custom-radio-wrap form .form-group:last-child {
    margin-bottom: 0;
}

.custom-radio-wrap form .form-group label {
    -webkit-appearance: none;
    background-color: #fafafa;
    border: 1px solid #cacece;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
    padding: 8px;
    border-radius: 50px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    position: absolute;
    top: 5px;
    left: 0;
}

.custom-radio-wrap form .form-group .label-text {
    vertical-align: middle;
    padding-left: 10px;
    margin-left: -5px;
    color: #6d6d6d;
    font-size: 17px;
    @include breakpoint(desktop) {
        font-size: 13px;
    }
}

.custom-radio-wrap form .form-group input {
    display: none;
    cursor: pointer;
}

.custom-radio-wrap form .form-group input:checked+label {
    background-color: #fff;
    color: #fff;
    border: 1px solid #f18121;
}

.custom-radio-wrap form .form-group input:checked~.label-text {
    color: #000;
    font-weight: 400;
}

.custom-radio-wrap form .form-group input:checked+label:after {
    content: "";
    width: 10px;
    height: 10px;
    border-radius: 50px;
    position: absolute;
    top: 3px;
    left: 3px;
    background: #f18121;
    text-shadow: none;
    font-size: 32px;
}

.heading {
    margin-bottom: 40px;
    @include breakpoint(desktop) {
        margin-bottom: 30px;
    }
}

select,
textarea,
input.form-control {
    &:focus {
        outline: none;
        box-shadow: none;
    }
}

span.fa.fa-star {
    color: #dddddd;
}

span.fa.fa-star.checked {
    color: #d59d9d;
}

// form#shutterbee-profile-form .avatar-upload .avatar-edit label {
//   background-color: transparent !important;
//   padding: 0 !important;
//   background-position: center !important;
//   background-size: contain !important;
//   background-repeat: no-repeat !important;
//   cursor: pointer !important;
//   box-shadow: none !important;
//   border: none !important;
//   margin-bottom: 10px;
//   width: 100px;
//   height: 100px;
// }
// form#shutterbee-profile-form .avatar-upload .avatar-preview {
//     position: absolute !important;
//     top: 6px;
//     left: 110px;
// }
// form#shutterbee-profile-form .avatar-upload .avatar-preview div#imagePreview {
//     width: 100px;
//     height: 88px;
// }
.preview-vent {
    h1 {
        margin-bottom: 60px;
        @include breakpoint(desktop) {
            margin-bottom: 50px;
        }
    }
    table.preview-table {
        th {
            font-size: 15px;
            padding: 10px 20px;
            text-transform: capitalize;
            border: 1px solid #303030;
        }
        td {
            padding: 10px 20px;
            font-size: 14px;
            width: 15%;
            border: 1px solid #303030;
            p {
                font-size: 14px;
                margin-bottom: 0;
            }
        }
    }
}

section.thank-you.book-your-event.event-book-flow.event-product-page.event-booking-form {
    padding: 200px 0 80px 0;
    @include breakpoint(desktop) {
        padding: 150px 0 70px 0;
    }
    @include breakpoint(normal) {
        padding: 130px 0 60px 0;
    }
    @include breakpoint(ipad) {
        padding: 100px 0 60px 0;
    }
}