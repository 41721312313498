@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
    font-family: $sans-serif;
    font-weight: normal;
    line-height: 1.6;
    font-size: $base-font-size + px;
    color: $color-text;
    counter-reset: section;
}
.nav-link {
    padding: 0px !important;
    font-size: 18px;
    color: #fff;
    font-weight: 500;
    &:hover{
        color: #dcdcdc;
    }
    @include breakpoint(desktop) {
        font-size: em(15);
    }
    @include breakpoint(normal) {
        font-size: em(14);
    }
}
.navbar{
    padding: 0;
}
a.navbar-brand {
    padding: 0;
    margin: 0;
    @include breakpoint(desktop) {
        width: 210px;
    }
    @include breakpoint(normal) {
        width: 170px;
    }
}
h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $sans-serif;
    font-weight: 400;
    line-height: 1.1;
    margin: 0 0 10px;
}


h1 {
    font-size: em(46);
    font-weight: 600;
    color: #000;

    @include breakpoint(desktop) {
        font-size: em(36);
    }

    @include breakpoint(normal) {
        font-size: em(30);
    }

    @include breakpoint(ipad) {
        font-size: em(22);
    }

    @include breakpoint(mobile) {
        font-size: em(20);
    }
}

h2 {
    font-size: em(40);
    font-weight: 500;
    margin-bottom: 25px;

    @include breakpoint(desktop) {
        font-size: em(32);
        margin-bottom: 15px;
    }

    @include breakpoint(normal) {
        font-size: em(24);
    }

    @include breakpoint(ipad) {
        font-size: em(20);
    }

    @include breakpoint(mobile) {
        font-size: em(18);
    }
}

h3 {
    font-size: em(32);
    font-weight: 600;

    @include breakpoint(desktop) {
        font-size: em(26);
    }

    @include breakpoint(ipad) {
        font-size: em(22);
    }

    @include breakpoint(mobile) {
        font-size: em(18);
    }
}

h4 {
    font-size: em(28);

    @include breakpoint(desktop) {
        font-size: em(24);
    }

    @include breakpoint(ipad) {
        font-size: em(20);
     }
}

h5 {
    font-size: em(26);
    font-weight: 600;
    line-height: 1.4;
    color: #000;

    @include breakpoint(desktop) {
        font-size: em(20);
    }
}

h6 {
    font-size: em(24);
    font-weight: 500;

    @include breakpoint(desktop) {
        font-size: em(20);
    }
}

p {
    font-size: em(17);
    font-family: $sans-serif;
    line-height: 1.6;
    margin-bottom: 30px;
    color: $color-text;
    font-weight: 500;

    @include breakpoint(desktop) {
        font-size: 15px;
        margin-bottom: 20px;
    }

    @include breakpoint(normal) {
        font-size: 0.80em;
        margin-bottom: 6px;
      }

    strong {
        font-family: $sans-serif;
        font-weight: 700;
        font-size: 16px;
        color: #000;
    }

    a {
        color: #343738;
        padding: 0;

        &:hover,
        &:focus {
            color: $color-secondary;
        }
    }
}