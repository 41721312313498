.home-banner.inner-banner.privacy-banner{
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    &::before{
        content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    }
    .home-banner-content{
        &::before{
            display: none;
        }
    }
}
.privacy-policy-content{
    width: 100%;
    max-width: 950px;
    margin: auto;
    p{
        margin-bottom: 30px;
        @include breakpoint(desktop) {
           margin-bottom: 20px;
            }
    }
    h6{
        color: #000;
        font-weight: 600;
        margin-bottom: 20px;
        line-height: 1.6;
    }
    ul{
        margin-bottom: 30px;
        li {
            margin-bottom: 15px;
            font-weight: 500;
            position: relative;
            padding-left: 22px;
            font-size: 17px;
            @include breakpoint(desktop) {
               font-size: 15px;
               margin-bottom: 10px;
                }
            &::before{
                content: "";
                position: absolute;
                left: 0;
                top: 9px;
                background: #3e8993;
                width: 7px;
                height: 7px;
                border-radius: 100%;
                @include breakpoint(desktop) {
                   width: 6px;
                   height: 6px;
                     }
            }
        }
    }
}