.booking ul li:not(:last-child)::after {
  content: "";
  position: absolute;
  top: -18px;
  left: 45%;
  width: 100%;
  height: 2px;
  background: #ccc;
}

.booking ul li::before {
  content: "";
  position: absolute;
  left: 45%;
  top: -24px;
  width: 12px;
  height: 12px;
  border-radius: 100%;
  background: #ccc;
}

.booking ul li {
  position: relative;
  font-size: clamp(9px, 1vw, 16px);
  width: 25%;
  font-weight: 600;
  text-transform: uppercase;
  list-style: none;
}

.booking ul {
  display: flex;
}

.event-setup-form-box {
  width: 100%;
  max-width: 700px;
  margin: auto;
  background: #eaeaea;
  padding: 40px 40px;
}

.event-setup-form-box .checkbox-custom-err .invalid-feedback {
  display: none !important;
}
.event-setup-form-box .checkbox-custom-err p {
  /* display: none; */
  width: 100%;
  padding: 0;
  margin: 0;
  margin-top: -10px;
  color: #dc3f45;
  font-size: 14px;
}

.places-autocomplete-container input {
  margin-bottom: 0.5rem;
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.places-autocomplete-container input:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.label-icon-con {
  position: relative;
}

.tooltip-container {
  position: absolute;
  top: -65px;
  left: -200px;
  width: 250px;
  height: 70px;
  background-color: black;
  color: white;
  padding: 2px;
  border-radius: 4px;
  text-align: center;
  display: none;
}

.tooltip-event-container {
  position: absolute;
  top: -28px;
  left: -150px;
  width: 200px;
  height: 30px;
  background-color: black;
  color: white;
  padding: 2px;
  border-radius: 4px;
  text-align: center;
  display: none;
}

.tooltip-text {
  font-size: 10px;
  line-height: 1.2;
}

.label-icon-con:hover .tooltip-container {
  display: block;
}

.label-icon-con:hover .tooltip-event-container {
  display: block;
}

.tooltip-arrow {
  position: absolute;
  bottom: -5px;
  left: 80%;
  transform: translateX(-50%);
  border-top: 5px solid black;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
}

.text-danger {
  color: #000 !important;
  margin: 0;
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
  background-image: none !important;
  padding: 0.375rem 0.75rem !important;
}

.form-control.is-invalid.icon-error-issue{
  padding-right: 2.5rem !important;
}

.card {
  flex-direction: row;
  max-width: 50%;
}
.card img {
  width: 30%;
}

.color-custom {
  color: #3e8993;
}

.card-border-left {
  border-left: 1px solid rgba(0, 0, 0, 0.125);
}

.container.event-detail .booking ul li:nth-child(-n + 2)::before,
.container.event-detail .booking ul li:nth-child(-n + 1)::after {
  background: #3e8993;
  z-index: 3;
}

.container.select-shutterb .booking ul li:nth-child(-n + 3)::before,
.container.select-shutterb .booking ul li:nth-child(-n + 2)::after {
  background: #3e8993;
  z-index: 3;
}

.container.add-reel .booking ul li:nth-child(-n + 4)::before,
.container.add-reel .booking ul li:nth-child(-n + 3)::after {
  background: #3e8993;
  z-index: 3;
}

.container.cart .booking ul li:nth-child(-n + 5)::before,
.container.cart .booking ul li:nth-child(-n + 4)::after {
  background: #3e8993;
  z-index: 3;
}

.card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.card {
  flex-basis: 300px;
  max-width: 100%;
}

.card-body {
  max-width: 100%;
}

.left-img {
  width: 50px;
}

.right-content {
  flex: 1;
}

.show_continue_booking_shutterb {
  position: fixed;
  bottom: 0;
  background: #fff;
  width: 100%;
  height: 66px;
  padding: 10px 0;
  left: 0;
  z-index: 9;
  box-shadow: 4px 2px 10px 2px #a9a9a9;
}

.card.selected {
  border: 2px solid #3e8993;
}

.image-container {
  position: relative;
  overflow: hidden;
  margin-right: 0;
}

.select-shutterB-next-btn,
.select-shutterB-prev-btn{
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: transparent;
  border: none;
  color: #3e8993;
  font-size: 24px;
  line-height: 1;
  padding: 0;
  display: flex;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease;
  background-color: #3e8993;
  height: 50px !important;
  width: 50px !important;

}

.prev-btn,
.next-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: transparent;
  border: none;
  color: #3e8993;
  font-size: 24px;
  line-height: 1;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease;
  background-color: #3e8993;
  height: 45px !important;
  width: 35px !important;
  opacity: 0.7;
}

.prev-btn {
  left: -1px !important;
}

.next-btn {
  right: -1px !important;
}

.text-style {
  font-weight: 600 !important;
  color: #000;
}

.apply-coupon-btn {
  font-size: 0.75rem;
  margin: 0;
  line-height: 1;
  cursor: pointer;
  position: relative;
  text-decoration: none;
  overflow: visible;
  padding: 0.618em 1em;
  font-weight: 700;
  border-radius: 3px;
  left: auto;
  color: #515151;
  background-color: #ebe9eb;
  border: 0;
  display: inline-block;
  background-image: none;
  box-shadow: none;
  text-shadow: none;
}

.row-container {
  display: flex;
}

.column {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.row {
  margin: 0;
  font-weight: 500;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 1px solid #dee2e6;
  font-size: 14px;
}

.cartt-body {
  max-width: 100%;
  padding: 0 !important;
}

.cartt {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}

.brdr-btm {
  border-bottom: 1px solid rgb(222, 226, 230);
}

.cart-image {
  width: 100px;
  height: 100px !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.outer-border {
  border: 1px solid #dee2e6;
  border-radius: 5px 0 0 5px;
  border-collapse: collapse;
}

.cart-footer{
  font-size: 10px;
  padding: 0px;
  margin: 0px;
  pointer-events: none;
}
.header-border {
  border-top: hidden;
  border-bottom: 1px solid rgb(222, 226, 230);
}

.brdr-top-hidden {
  border-top: hidden;
}

.bordr-top-hidden {
  border-top: hidden !important;
}

.brdr-btm-hidden {
  border-bottom: hidden;
}

.bordr-btm-hidden {
  border-bottom: hidden !important;
}

.hover-effect:hover {
  color: white !important;
  background-color: #3e8993;
}

.undo-bar {
  padding: 1em 2em 1em 3.5em;
  margin: 0 0 2em;
  position: relative;
  background-color: #f7f6f7;
  color: #515151;
  border-top: 3px solid #8fae1b;
  list-style: none outside;
  width: auto;
  word-wrap: break-word;
}

.undo-bar::before {
  content: "\e015";
  color: #8fae1b;
}

.disable-checkout {
  pointer-events: none;
  cursor: none;
}

.custom-min-height {
  min-height: 70vh;
}

.cursor-point {
  cursor: pointer !important;
}

.booking-prev-next-btns {
  width: 30px !important;
  height: 30px !important;
  box-shadow: 0 0 10px #cbcbcb !important;
  opacity: 1 !important;
  font-size: 17px !important;
  z-index: 2 !important;
  font-weight: 600 !important;
}

.booking-prev-btn {
  left: -14px !important;
}

.booking-next-btn {
  right: -14px !important;
}

.overflow-visible {
  overflow: visible !important;
}

.flex-basis-450 {
  flex-basis: 400px !important;
}

.mw-mh-12 {
  min-width: 12px !important;
  min-height: 12px !important;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box {
  width: 105px !important;
}

.react-datepicker__time-container {
  width: 105px !important;
}

.underline-on-hover:hover {
  text-decoration: underline;
}

.underline-on-hover {
  color: #343738 !important;
}

.suggestion-zIndex{
  position: relative !important;
  z-index: 2 !important;
}

.hw-70{
  height: 70px !important;
  width: 70px !important;
}

.fw-600{
  font-weight: 600 !important;
}

.flex-basis-500{
  flex-basis: 500px !important;
}

.min-max-height-360{
  min-height: 360px !important;
  max-height: 36px !important;
}

.min-max-height-110{
  min-height: 110px !important;
  max-height: 110px !important;
}

.font-size-14{
  font-size: 14px !important;
}

.min-max-height-160{
  min-height: 160px !important;
  max-height: 160px !important;
}

.min-max-height-50{
  min-height: 50px !important;
  max-height: 50px !important;
}

.booking-btn-custom-height{
  min-height: 60px !important;
  max-height: 60px !important;
  line-height: 2.2 !important;
}

.booking-a-custom-height{
  font-weight: 400 !important;
  font-size: 18px !important;
  height: 40px !important;
  line-height: 2.2 !important;
}
.edit-icon {
  /* width: 20px !important; */
  cursor: pointer !important;
  /* height: 20px !important; */
  padding:0px 0px 0px 0px !important;
  margin : 0px 0px 0px 0px !important;
  /* font-weight: bolder; */
  color: #3e8993;
  /* font-size:30%; */
}

.font-13{
  font-size: 13px;
}

.font-14{
  font-size: 14px;
  font-weight: 500;
  color: #000;
}


