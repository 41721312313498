.email-confirmation-con{
  min-height: 100vh;
}
.email-confirmation{
  font-family: $sans-serif;
}
.email-icon-height{
   height: 8rem !important;
}

.email-icon-color{
   fill: #3e8993
}

.m-6{
   margin-top: 4rem !important;
   margin-bottom: 4rem !important;
}

.password-field-container {
   position: relative;
 }
 
 .password-field-container input {
   padding-right: 30px; /* Add space for the eye icon */
 }
 
 .password-toggle-icon {
   position: absolute;
   top: 50%;
   right: 10px;
   transform: translateY(-50%);
   cursor: pointer;
 }
 
 .password-toggle-icon svg {
   width: 24px;
   height: 24px;
   fill: #999;
 }
 
 .password-toggle-icon:hover svg {
   fill: #333;
 }
 
 .image-container {
   position: relative;
   display: inline-block;
   margin-right: 10px;
 }
 
 .remove-icon {
   position: absolute;
   top: 0;
   right: 0;
   width: 20px;
   height: 20px;
   background-color: #fff;
   border-radius: 50%;
   display: flex;
   justify-content: center;
   align-items: center;
   cursor: pointer;
 }
 
 .remove-icon:hover {
   background-color: #f1f1f1;
 }
 
 .remove-icon svg {
   //fill: #ff0000;
 }