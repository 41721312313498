.spinner{
    position: fixed;
    top: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0,0.4);
    z-index: 1001;
    color: white;
    div{
        svg{
            width: 80px;
            height: 80px;
        }
    }
}
.my-events-sb-gallery-overlay{
    position: fixed;
    z-index: 1002;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0,0.3);
    cursor: progress;
}