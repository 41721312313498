.btn {
  display: inline-block;
  width: auto;
  border: none;
  color: #fff;
  font-size: 24px;
  text-transform: uppercase;
  letter-spacing: 0.01em;
  line-height: 2.7;
  padding: 0 80px;
  -webkit-transform: none;
  transform: none;
  height: 66px;
  text-indent: 0%;
  outline: none;
  min-width: 100px;
  position: relative;
  cursor: pointer;
  border-radius: 13px;
  border: 2px solid #3e8993;
  background: #3e8993;
  position: relative;
  overflow: hidden;
  font-family: $sans-serif;
  transition: all 0.5s;
  font-weight: 500;
  &:hover {
    background: #fff;
    color: #3e8993;
    border: 2px solid #3e8993;
    box-shadow: 0px 10px 10px #4a4a4a;
  }
  @include breakpoint(desktop) {
    font-size: 18px;
    line-height: 2.4;
    padding: 0 40px;
    height: 45px;
  }
  @include breakpoint(normal) {
    height: 40px;
    font-size: 15px;
  }

  // &:hover {
  //     color: #1e77c1;
  // border: 2px solid #1e77c1;
  // }
  // &::before{
  //     width: 100%;
  //     height: 100%;
  //     content: '';
  //     margin: auto;
  //     position: absolute;
  //     top:  0%;
  //     left: 0%;
  //     background:  #1e77c1;
  //     transition: all .5s;
  //     z-index: -1;
  // }
  // &::after{
  //     width: 100%;
  //     height: 100%;
  //     content: '';
  //     margin: auto;
  //     position: absolute;
  //     top:  0%;
  //     left: 0%;
  //     background:  #1e77c1;
  //     transition: all .5s;
  //     z-index: -1;
  // }
}

.back-btn {
  display: inline-block;
  width: auto;
  border: none;
  color: #fff;
  font-size: 24px;
  text-transform: uppercase;
  letter-spacing: 0.01em;
  line-height: 2.7;
  padding: 0 80px;
  -webkit-transform: none;
  transform: none;
  height: 66px;
  text-indent: 0%;
  outline: none;
  min-width: 100px;
  position: relative;
  cursor: pointer;
  border-radius: 13px;
  border: 2px solid #818181;
  background: #818181;
  position: relative;
  overflow: hidden;
  font-family: $sans-serif;
  transition: all 0.5s;
  font-weight: 500;
  &:hover {
    background: #fff;
    color: #818181;
    border: 2px solid #818181;
    box-shadow: 0px 10px 10px #4a4a4a;
  }
  @include breakpoint(desktop) {
    font-size: 18px;
    line-height: 2.4;
    padding: 0 40px;
    height: 45px;
  }
  @include breakpoint(normal) {
    height: 40px;
    font-size: 15px;
  }
}

.btn-simple {
  border: none;
  color: $color-white !important;
  font-size: 24px;
  text-transform: uppercase;
  letter-spacing: 0.01em;
  padding: 0 80px;
  -webkit-transform: none;
  transform: none;
  height: 66px;
  text-indent: 0%;
  outline: none;
  min-width: 100px;
  position: relative;
  cursor: pointer;
  border-radius: 13px;
  border: 2px solid #3e8993;
  background: #3e8993;
  position: relative;
  overflow: hidden;
  font-family: $sans-serif;
  transition: all 0.5s;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  &:hover {
    border: 2px solid #3e8993;
    opacity: 0.7;
  }
  @include breakpoint(desktop) {
    font-size: 18px;
    padding: 0 40px;
    height: 45px;
  }
  @include breakpoint(normal) {
    height: 40px;
    font-size: 14px;
  }

  // &:hover {
  //     color: #1e77c1;
  // border: 2px solid #1e77c1;
  // }
  // &::before{
  //     width: 100%;
  //     height: 100%;
  //     content: '';
  //     margin: auto;
  //     position: absolute;
  //     top:  0%;
  //     left: 0%;
  //     background:  #1e77c1;
  //     transition: all .5s;
  //     z-index: -1;
  // }
  // &::after{
  //     width: 100%;
  //     height: 100%;
  //     content: '';
  //     margin: auto;
  //     position: absolute;
  //     top:  0%;
  //     left: 0%;
  //     background:  #1e77c1;
  //     transition: all .5s;
  //     z-index: -1;
  // }
}

// .btn.banner-btn{
//     position: relative;
//     height: 65px;
//     line-height: 2.8;
//     padding: 0 35px;
//     @include breakpoint(desktop) {
//         height: 52px;
//         line-height: 2.7;
//         padding: 0 30px;
//       }
//     &:hover{
//         color:#fff;
//         border: 2px solid #fff;
//     }
// }

// .btn-bg-transparent {
//     background: transparent;
//     border: 2px solid #2d93fd;
//     color: #2d93fd;
//     font-weight: 600;
//     @include breakpoint(desktop) {
//         line-height: 2.7;
//     }
//     @include breakpoint(normal) {
//         line-height: 2.8 !important;
//       }

//     &:hover{
//         background: transparent;
//         border: 2px solid #2d93fd;
//         color: #2d93fd;
//     }
// }

// .form-btn {
//     height: 55px;
//     font-size: 20px !important;
//     padding: 0 0;
//     width: 170px;
//     @include breakpoint(desktop) {
//         height: 44px;
//         font-size: 18px!important;
//         padding: 0 0;
//         width: 150px;
//         line-height: 2.6;
//         @include breakpoint(normal) {
//             height: 40px;
//             font-size: 16px !important;
//             width: 130px;
//           }
//           @include breakpoint(ipad) {
//             height: 36px;
//             font-size: 15px !important;
//          }
//          @include breakpoint(mobile-m) {
//             width: 40%;
//         }
//         @include breakpoint(mobile-s) {
//             width: 80%;
//         }
//     }
// }
// .btn-lg{
//     padding: 0 40px;
//     height: 55px;
//     background: #1e77c1;
//     color: #fff;
// }
// .btn-sm{
//     padding: 0 20px;
//     height: 35px;
//     font-size: 19px;
//     background: #1e77c1;
//     color: #fff;
// }

// .btn:hover:before{
//         transform: rotateX(90deg);

// }
// .btn:hover:after{
//         transform: rotateY(90deg);

// }
