.event-booking-add-reel {
  label {
    font-size: 14px;
    display: inline;
  }
  form {
    min-width: 100%;
    input[type="checkbox"] {
      vertical-align: middle;
    }
    select {
      padding: 5px 10px;
      padding-right: 30px;
      font-family: "Montserrat", sans-serif;
      border: 1px solid #3e8993;
      border-radius: 5px;
      display: inline;
    }
    .price-per-reel {
      min-width: 60px;
      text-align: center;
    }
    // select drop arrow styles
    select[name="reelQtyWithoutLogo"],
    select[name="reelQtyWithLogo"] {
      color: $color-primary;
    }
    select.minimal {
      background-image: linear-gradient(45deg, transparent 50%, gray 50%),
        linear-gradient(135deg, gray 50%, transparent 50%),
        linear-gradient(to right, #ccc, #ccc);
      background-position: calc(100% - 20px) calc(1em + 2px),
        calc(100% - 15px) calc(1em + 2px), calc(100% - 2.5em) 0.5em;
      background-size: 5px 5px, 5px 5px, 1px 1.5em;
      background-repeat: no-repeat;
    }
    select.minimal:focus {
      background-image: linear-gradient(45deg, green 50%, transparent 50%),
        linear-gradient(135deg, transparent 50%, green 50%),
        linear-gradient(to right, #ccc, #ccc);
      background-position: calc(100% - 15px) 1em, calc(100% - 20px) 1em,
        calc(100% - 2.5em) 0.5em;
      background-size: 5px 5px, 5px 5px, 1px 1.5em;
      background-repeat: no-repeat;
      border-color: $color-primary;
      outline: 0;
    }
    select:-moz-focusring {
      color: transparent;
      text-shadow: 0 0 0 #000;
    }
    h5 {
      span {
        text-decoration: underline;
      }
    }

    textarea,
    input[type="text"] {
      padding: 5px 10px;
      font-family: $sans-serif;
      border: 1px solid $color-btn;
      margin-bottom: 1rem;
      border-radius: 5px;
    }
    textarea {
      min-height: 110px;
    }
    input[name="terms"] {
      width: 32px;
      height: 32px;
      accent-color: $color-primary;
    }
    input[type="radio"] {
      accent-color: $color-primary;
    }

    // subtotal input
    .sub-total {
      padding: 5px 0px;
      box-sizing: border-box;
      font-family: "Montserrat", sans-serif;
      border: 1px solid #3e8993;
      border-radius: 5px;
      width: fit-content;
      ::first-letter {
        font-size: 8px;
      }
      //   padding-left: 14px;
      //   background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='16px' width='85px'><text x='2' y='13' fill='gray' font-size='14px' font-family='arial'>$</text></svg>");
      //   background-repeat: no-repeat;
      //   background-position-y: center;
    }
    .logo-con {
      padding: 30px;
      border-radius: 5px;
      border: 1px dotted black;
      text-align: center;
      height: 100%;
      width: 100%;
      overflow: hidden;
      .logo {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        flex-wrap: wrap;
        width: fit-content;
        margin: auto;
        p {
          width: 100%;
          overflow: hidden;
        }
        svg {
          width: 64px;
          height: 64px;
        }
        .logo-preview {
          width: 50px;
          height: 50px;
          border: 1px solid black;
          border-radius: 5px;
          padding: 5px;
        }
      }
    }
    .sub-heading {
      color: $color-text;
    }
  }
}
.add-reel-row {
  vertical-align: center;
  td {
    vertical-align: middle;
  }
}

.undo-cart {
  width: 100vw;
  background-color: grey;
  background-color: #f7f6f7;
  border-top: 3px solid #8fae1b;
  padding: 1em 2em 1em 3.5em;
  margin-top: 8px;
  position: relative;
  color: #515151;
  list-style: none outside;
  width: auto;
  word-wrap: break-word;
  &:before {
    content: "✔";
    color: white;
    font-size: 14px;
    padding: 1px 4px;
    background: #8fae1b;
    border-radius: 50%;
    box-sizing: border-box;
    margin-right: 10px;
  }
}
