.blog.blog-detail-blog.blog-detail2-blog{
    .heading{
        h3{
            text-align: left;
        }
    }
}
.blog-detail.blog-detail2{
    padding-bottom: 0;
    p{
        &:last-child{
            margin-bottom: 0;
        }
    }
    .blog-detail-top-banner-content{
        h6 {
            line-height: 1.4;
            text-transform: uppercase;
            font-weight: 600;
            
            a{
                text-transform: uppercase;
                color: #000;
                font-weight: 600;
                font-size: 22px;
                background: transparent;
                padding: 0;
                @include breakpoint(desktop) {
                    font-size: 18px;
                      }
            }
        }
        p{
            &:first-of-type{
                color: #606060;
                font-weight: 600;
                margin-bottom: 10px;
                span{
                    margin: 0 0 0 20px;
                    position: relative;
                    padding-left: 20px;
                    &::before{
                        content: "";
                        position: absolute;
                        top: 6px;
                        left: 0;
                        background: #606060;
                        width: 5px;
                        height: 5px;
                        border-radius: 100%;
                    }
                }
            }
            &:nth-of-type(2){
                margin-top: 15px;
            }
        }
        a{
            background: #ebebeb;
    font-size: 15px;
    color: #666666;
    padding: 2px 10px;
    font-weight: 600;
    transition: all 0.3s;
        }
    }
    .row{
        margin-bottom: 30px;
    }
    figure{
        position: relative;
        width: 100%;
        height: 610px;
        @include breakpoint(desktop) {
            height: 510px;
            margin: 0 0 0 -25px;
              }
        img{
            width: auto;
            height: 100%;
            object-fit: cover;
        }
        &::after{
            content: "";
            position: absolute;
            right: 20px;
            top: 30px;
            background: #f2f2f2;
            width: 640px;
            height: 600px;
            z-index: -1;
            @include breakpoint(desktop) {
                right: -20px;
                width: 500px;
                height: 500px;
                  }
        }
    }
    .blog2-relate{
        position: relative;
        .share-icon {
            position: absolute;
            right: 59px;
            top: 0;
            font-size: 22px;
            background-color: rgba(255, 255, 255, 0.5);
            padding: 0 8.5px;
            z-index: 1;
            @include breakpoint(desktop) {
                right: 40px;
                  }
            a{
                color: #474747;
            }
        }
        .search-form {
            position: absolute;
            top: 35px;
            right: 59px;
            transform: translateY(20px);
            opacity: 0;
            pointer-events: none;
            transition: all ease-in-out 0.3s;
            z-index: 1;
            @include breakpoint(desktop) {
                right: 40px;
                  }
        
            &.open
            {
                transform: translateY(0);
                opacity: 1;
                pointer-events: visible;
            
            }
        }
        .search-form{
            ul{
                li{
                    a {
                        color: #74b2bc;
                        font-size: 22px;
                        background-color: azure;
                        display: flex;
                        justify-content: center;
                        padding: 10px 8px;
                        margin: 0 0 0px 0;
                    }
                }
            }
        }
        .close {
            float: none;
            font-size: 22px;
            font-weight: 600;
            line-height: 0;
            color: #474747;
            text-shadow: none;
            opacity: 1;
           
        }
    }
}